import { isYoutube, getYoutubeId } from '@atoms/VideoFrame/Utils/video'
import DefaultVideoThumbnail from '@images/default-video-thumbnail.jpg'

export const isVideo = (url: string): boolean => {
  return /youtu\.?be|vimeo\.com|wistia\.com/.test(url)
}

export const displayMediaThumb = (url: string): string => {
  if (!isVideo(url)) {
    return url
  }

  if (isYoutube(url)) {
    const videoId = getYoutubeId(url)
    return `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`
  }

  return DefaultVideoThumbnail
}
