import { useRouter } from 'next/router'
import { useState } from 'react'
import { Box } from 'rebass'

import Loader from '@atoms/Loader/Loader'
import LoginOrCreateAccount from '@molecules/LoginOrCreateAccount/LoginOrCreateAccount'
import UserAuth from '@concepts/Auth/views/UserAuth'
import FreebiesHeader from '@concepts/Freebies/views/FreebiesHeader/FreebiesHeader'

import { useAuthContext } from '@concepts/Auth/store/context'
import noop from '@utils/noop'

type Props = {
  title: string
  text: string
  btnOnClick?: Function
  disableButton?: boolean
  beforeShareClick?: Function
}

enum Forms {
  Login = 'login',
  SignUp = 'signup'
}

const SaleAuthForm: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  text,
  btnOnClick,
  disableButton = false,
  beforeShareClick
}) => {
  const router = useRouter()
  const [{ user, loading }] = useAuthContext()
  const [selectedForm, setSelectedForm] = useState<Forms | null>(null)

  const toggleForm = () => {
    setSelectedForm(selectedForm === Forms.Login ? Forms.SignUp : Forms.Login)
  }

  if (loading) {
    return (
      <Loader
        sx={{
          position: 'relative',
          width: 60,
          height: 10,
          mx: 'auto',
          my: 4
        }}
        data-testid="loader"
      />
    )
  }

  if (user) {
    return (
      <FreebiesHeader
        title={title}
        btnOnClick={btnOnClick}
        disableButton={disableButton}
        beforeShareClick={beforeShareClick}
      />
    )
  }

  if (selectedForm) {
    return (
      <Box mb={7}>
        <UserAuth
          returnTo={router.asPath}
          onSubmit={noop}
          onClickGuestChange={toggleForm}
          signUpFirst={selectedForm === Forms.SignUp}
        />
      </Box>
    )
  }

  return (
    <Box mb={7}>
      <LoginOrCreateAccount
        text={text}
        setLogin={() => setSelectedForm(Forms.Login)}
        setSignUp={() => setSelectedForm(Forms.SignUp)}
      />
    </Box>
  )
}

export default SaleAuthForm
