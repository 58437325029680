import { findLast, isEmpty, not } from 'ramda'
import { ChangeEvent, useEffect, useState } from 'react'
import { debounce } from 'throttle-debounce'

import { ShoppingCartIcon } from '@atoms/Icon/Icon'
import Select from '@atoms/Select/Select'
import { OptionList } from '@atoms/Select/types'
import Button from '@atoms/UIButton/Button'
import Container from '@atoms/UIContainer/Container'
import Link from '@atoms/UILink/Link'

import { useAddToCartContext } from '@concepts/Sales/store/addToCartContext'
import { SaleDetailsType } from '@concepts/Sales/types/sale'
import useMediaQuery from '@hooks/useMediaQuery'

import { Box, Text } from '@lib/UIComponents'

import useAddSaleToCart, {
  useAddSaleToCartType
} from '@molecules/AddSaleToCart/hooks/useAddSaleToCart'
import { defaultMediaSizes } from '@theme/breakpoints'
import { AddToCartButtonType, CartItemSource } from 'src/generated/graphql'
import { useSaleTab } from './hooks/useSaleTab'
import useScrollToElement from './hooks/useScrollToElement'
import { usePublisherContext } from '@concepts/Publisher/store/context'

export type TabItem = {
  label: string
  value: string
  hidden?: boolean
}

export type SaleTabsProps = {
  tabs: TabItem[]
  hideMobileTab?: boolean
  sale?: SaleDetailsType
}

const styles = {
  containerWrapper: {
    position: 'sticky',
    top: 5,
    zIndex: 1
  },
  container: {
    backgroundColor: 'white',
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderColor: 'grays.100'
  },
  bottomBar: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    background: 'white',
    borderTop: '1px solid',
    borderColor: 'grays.50',
    zIndex: 'header'
  },
  selectWrapper: {
    flex: 1
  },
  field: {
    select: {
      border: 0,
      px: '20px',
      py: 4,
      borderBottom: '1px solid',
      borderColor: 'grays.50',
      borderRadius: 0,
      color: 'blacks.500',
      option: { color: 'blacks.500' }
    }
  },
  arrowIcon: {
    mr: 3
  },
  btn: {
    width: '100%',
    maxWidth: '125px',
    h: 'auto',
    px: 2,
    justifyContent: 'center',
    borderRadius: 0,
    svg: {
      mr: 3
    }
  },
  header: {
    py: '30px',
    justifyContent: 'space-around',
    display: 'flex'
  },
  tab: (isActive: boolean) => {
    return {
      color: 'blacks.500',
      fontSize: [3, 3, 3, 4, '2xl'],
      fontWeight: 'bold',
      position: 'relative',
      cursor: 'pointer',
      '&:before': {
        content: '""',
        display: 'block',
        height: '6px',
        width: isActive ? '100%' : 0,
        left: 0,
        bottom: -2,
        backgroundColor: 'primary',
        position: 'absolute',
        transition: '300ms ease'
      }
    }
  }
}

const buyButtonTextFor = (sale: SaleDetailsType) => {
  if (sale.fromAffiliate && sale.affiliateBuyButtonText !== '') {
    return sale.affiliateBuyButtonText
  }
  return sale.isRecurringTrial ? 'Free Trial' : 'Add'
}

const SaleTabs = ({
  tabs,
  hideMobileTab,
  sale = {} as SaleDetailsType
}: SaleTabsProps) => {
  const [currentTab, setCurrentTab] = useState(tabs[0])
  const { addToCart, warrantyId, quantityLeft, quantity } = useAddSaleToCart({
    sale
  } as useAddSaleToCartType)
  const { isSubmitting, purchaseButton } = useAddToCartContext()
  const { scrollToElementId } = useScrollToElement()
  const { databaseId: publisherId } = usePublisherContext()

  const isMobile = useMediaQuery(defaultMediaSizes.ScreenM)

  const isQuantityInvalid = !quantityLeft || quantity > quantityLeft

  const { dispatchAffiliateButtonAnalytics } = useSaleTab()

  useEffect(() => {
    const findActiveTab = (scrollPosition: number) =>
      findLast((tab) => {
        const tabDOM = document.getElementById(tab.value) as HTMLElement

        return tabDOM && scrollPosition >= tabDOM.offsetTop
      }, tabs)

    const changeTabsOnScroll = () => {
      const headerHeight =
        (document.getElementById('header') as HTMLElement)?.offsetHeight || 0
      const saleTabs = document.getElementById('saleTabs') as HTMLElement
      if (saleTabs) {
        const tabsHeight = saleTabs.offsetHeight || 0

        const currentScrollPosition = window.scrollY + headerHeight + tabsHeight
        const newCurrentTab = findActiveTab(currentScrollPosition) || tabs[0]

        saleTabs.style.top = `${headerHeight - 1}px`

        /* istanbul ignore else */
        if (newCurrentTab) {
          setCurrentTab(newCurrentTab)
        }
      }
    }

    changeTabsOnScroll()
    const debouncedChangeTabsOnScroll = debounce(200, changeTabsOnScroll)

    window.addEventListener('scroll', debouncedChangeTabsOnScroll)

    return () => {
      debouncedChangeTabsOnScroll.cancel()
      window.removeEventListener('scroll', changeTabsOnScroll)
    }
  }, [tabs])

  if (isEmpty(tabs)) return null

  const scrollToTab = (tab: TabItem) => {
    const element = document.getElementById(tab.value)

    /* istanbul ignore else */
    if (element) {
      setCurrentTab(tab)
      scrollToElementId(tab.value)
    }
  }

  const onChangeSelectTab = (event: ChangeEvent<HTMLSelectElement>) => {
    const tab = tabs.find((tab) => tab.value === event.target.value)

    /* istanbul ignore else */
    if (tab) {
      scrollToTab(tab)
    }
  }

  const buyButtonText = buyButtonTextFor(sale)

  const shouldDisplayTab = !isEmpty(sale) && !hideMobileTab

  return (
    <Box sx={styles.containerWrapper} id="saleTabs">
      {shouldDisplayTab && isMobile && (
        <>
          <Box sx={styles.bottomBar}>
            <Box sx={styles.selectWrapper}>
              <Select
                sx={styles.field}
                optionsList={tabs as unknown as OptionList}
                value={currentTab.value}
                arrowIconStyles={styles.arrowIcon}
                onChange={onChangeSelectTab}
              />
            </Box>
            {purchaseButton?.type === AddToCartButtonType.AddToCart ? (
              <Button
                variant="primary"
                sx={styles.btn}
                aria-label={buyButtonText}
                disabled={isQuantityInvalid || isSubmitting}
                onClick={() =>
                  addToCart({
                    warranty: warrantyId,
                    source: CartItemSource.Pdp
                  })
                }
              >
                <ShoppingCartIcon />
                <Text>{buyButtonText}</Text>
              </Button>
            ) : (
              <Button
                as={Link}
                to={purchaseButton?.url as string}
                target={
                  purchaseButton?.type === AddToCartButtonType.BuyNow
                    ? '_blank'
                    : '_self'
                }
                variant="dark"
                sx={styles.btn}
                aria-label={purchaseButton?.name}
                onClick={() =>
                  dispatchAffiliateButtonAnalytics(sale, publisherId as number)
                }
              >
                {purchaseButton?.name}
              </Button>
            )}
          </Box>
        </>
      )}

      {not(isMobile) && (
        <Box sx={styles.container}>
          <Container>
            <Box sx={styles.header}>
              {tabs.map((tab) => (
                <Text
                  as="strong"
                  sx={styles.tab(tab.label === currentTab.label)}
                  key={tab.value}
                  data-testid="tabItem"
                  aria-selected={tab.label === currentTab.label}
                  onClick={() => scrollToTab(tab)}
                >
                  {tab.label}
                </Text>
              ))}
            </Box>
          </Container>
        </Box>
      )}
    </Box>
  )
}

export default SaleTabs
