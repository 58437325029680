import Heading from '@atoms/UIHeading/Heading'
import Container from '@atoms/UIContainer/Container'
import { Flex } from '@lib/UIComponents'

type TaglineProps = {
  title: string
  isCourse?: boolean
  id?: string
}

const Tagline = ({ title, id, isCourse = false }: TaglineProps) => {
  return (
    <Flex
      id={id}
      sx={{
        backgroundColor: isCourse ? 'white' : 'primaries.100',
        width: '100%'
      }}
    >
      <Container
        sx={{
          backgroundColor: 'transparent',
          py: [8, 8, 10, '100px'],
          px: [5, 4, 4],
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Heading
          as="h3"
          sx={{
            mx: 'auto',
            maxWidth: ['auto', '430px', '650px', '840px'],
            fontWeight: 'bold',
            lineHeight: '1.3',
            textAlign: 'center',
            pb: [1, 1, 1, 2],
            fontSize: [4, 5, 5, 6]
          }}
        >
          {title}
        </Heading>
      </Container>
    </Flex>
  )
}

export default Tagline
