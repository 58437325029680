import { useCountdownClock } from './hooks/useCountdownClock'

import { Text } from '@lib/UIComponents'
import { UIStyles } from '@lib/UIComponents/types'

type CountdownClockProps = {
  endDate: Date
  now?: Date
  sx?: UIStyles
}

const CountdownClock = ({
  endDate,
  sx,
  now = new Date()
}: CountdownClockProps) => {
  const countdown = useCountdownClock(endDate, now)

  return <Text sx={{ fontSize: ['md', 'lg'], ...sx }}>{countdown}</Text>
}

export { CountdownClock }
