import axios from 'axios'
import { MutableRefObject } from 'react'

import { getVimeoId, getYoutubeId } from './video'

export const getYoutubeThumb = (url: string): string => {
  const videoId = getYoutubeId(url)

  return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
}

export const getVimeoThumb = async (url: string): Promise<string> => {
  const videoId = getVimeoId(url)
  const response = await axios.get(
    `https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${videoId}&width=640`
  )

  return response?.data?.thumbnail_url
}

export const getWistiaThumb = (videoRef: MutableRefObject<any>): string => {
  return videoRef.current?.getInternalPlayer().thumbnailAssets().slice(-1)[0]
    .url
}
