import { formatCents } from '@utils/strings'
import { OgMetaDataType, TwitterMetaDataType } from '@concepts/Seo/Seo'

export type useSaleMetaData = {
  title: string
  imageUrl: string
  metaDescription: string
  priceInCents: number
  calculatedDiscount: number
}

type useSaleMetaDataReturnType = {
  ogMetaData: OgMetaDataType
  twitterMetaData: TwitterMetaDataType
}

const useSaleMetaData = ({
  title,
  imageUrl,
  metaDescription,
  priceInCents,
  calculatedDiscount
}: useSaleMetaData): useSaleMetaDataReturnType => {
  const imageObject = imageUrl ? { image: imageUrl } : {}

  const ogMetaData = {
    content: 'website',
    description: metaDescription,
    title,
    ...imageObject
  }

  const twitterMetaData = {
    card: 'product',
    title,
    description: metaDescription,
    dataOne: priceInCents === 0 ? 'FREE' : formatCents(priceInCents),
    labelOne: 'PRICE',
    dataTwo: String(calculatedDiscount) + '%',
    labelTwo: 'OFF',
    ...imageObject
  }

  return { ogMetaData, twitterMetaData }
}

export default useSaleMetaData
