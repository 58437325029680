import { ClockIcon } from '@atoms/Icon/Icon'
import { TimeLeft } from './TimeLeft'
import { Box, Flex, Text } from '@lib/UIComponents'
import { UIStyles } from '@lib/UIComponents/types'
import { SaleSummaryStyle } from '@molecules/SaleSummary/utils'

type MessageProps = {
  expiresAt: string
  applyRetailPrice: boolean
  soldOut: boolean
  priceDescription?: string
  placedOnImageBackground?: boolean
  variation?: SaleSummaryStyle
  priceDropEndsAt?: string
  sx?: UIStyles
}

const Message = ({
  priceDescription,
  expiresAt,
  applyRetailPrice,
  soldOut,
  placedOnImageBackground = false,
  variation = SaleSummaryStyle.Solo,
  priceDropEndsAt,
  sx = {}
}: MessageProps) => {
  const messageStyles = priceDropEndsAt
    ? {
        // We will need to pass these colors to chakra theme when refactor this component
        color: placedOnImageBackground ? 'reds.550' : 'danger',
        fontWeight: 'bold'
      }
    : {}

  return (
    <Flex sx={sx}>
      {priceDropEndsAt && !soldOut && (
        <Box
          sx={{
            marginTop: 1,
            mr: 1,
            ...messageStyles
          }}
        >
          <ClockIcon data-testid="clock-icon" />
        </Box>
      )}

      <Flex sx={{ alignItems: 'center', gap: 1 }}>
        <Text
          sx={{
            display: applyRetailPrice ? 'block' : 'none',
            fontSize: ['sm', 'md'],
            ...messageStyles
          }}
        >
          {priceDescription}
        </Text>

        {!soldOut && priceDropEndsAt && (
          <TimeLeft
            expiresAt={expiresAt}
            saleVariation={variation}
            priceDropEndsAt={priceDropEndsAt}
            messageStyles={messageStyles}
          />
        )}
      </Flex>
    </Flex>
  )
}

export default Message
