import { mergeDeepRight } from 'ramda'

import Link from '@atoms/UILink/Link'
import Button from '@atoms/UIButton/Button'
import Loader from '@atoms/Loader/Loader'
import { AddToCartButtonType } from 'src/generated/graphql'
import { SaleDetailsType } from '@concepts/Sales/types/sale'
import AddSaleToCart from '@molecules/AddSaleToCart/AddSaleToCart'
import { useAddToCartContext } from '@concepts/Sales/store/addToCartContext'
import { ExtractRecurringSaleGroupData } from '@organisms/ChooseRecurringPlan/utils/ExtractRecurringSaleGroupData'
import useScrollToElement from '@molecules/SaleTabs/hooks/useScrollToElement'
import { Flex } from '@lib/UIComponents'

const styles = {
  btnWrapper: {
    mb: 7,
    flex: '1 0 auto',
    justifyContent: 'center',
    minWidth: ['auto', '285px'],
    maxWidth: '285px',
    width: '100%'
  },
  btn: {
    width: '100%',
    justifyContent: 'center'
  },
  loader: {
    position: 'relative',
    width: '60px',
    height: '15px',
    mx: 'auto',
    my: 4
  }
}

type Props = {
  sale: SaleDetailsType
  warrantyId: number
  bidPrice?: number
  loaderSx?: object
  sx?: object
  paypalSx?: object
  showQuantity?: boolean
  placedOnImageBackground?: boolean
  showPaypal?: boolean
}

const SalePurchaseButton: React.FC<React.PropsWithChildren<Props>> = ({
  sale,
  warrantyId,
  bidPrice = 0,
  sx = {},
  loaderSx = {},
  paypalSx = {},
  showQuantity = true,
  showPaypal = true,
  placedOnImageBackground = false
}) => {
  const { isSubmitting, purchaseButton, isLoadingBtn } = useAddToCartContext()
  const { scrollToElementId } = useScrollToElement()

  if (isLoadingBtn)
    return (
      <Loader
        sx={mergeDeepRight(styles.loader, loaderSx)}
        data-testid="loader"
      />
    )

  const { type, url, name } = purchaseButton
  const { isChoosePlan } = ExtractRecurringSaleGroupData(
    sale.recurringSaleGroup
  )

  return (
    <>
      {type === AddToCartButtonType.AddToCart ? (
        <>
          {isChoosePlan ? (
            <Button
              variant="dark"
              aria-label="Choose Plan"
              onClick={() => scrollToElementId('choosePlanSection')}
              sx={{
                ...sx,
                justifyCOntent: 'center',
                maxWidth: ['none', '280px'],
                mb: 7,
                display: 'block'
              }}
            >
              Choose Plan
            </Button>
          ) : (
            <AddSaleToCart
              sx={sx}
              paypalSx={paypalSx}
              sale={sale}
              warrantyId={warrantyId}
              text={name}
              isSubmitting={isSubmitting}
              showQuantity={showQuantity}
              showPaypal={showPaypal}
              bidPrice={bidPrice}
              placedOnImageBackground={placedOnImageBackground}
            />
          )}
        </>
      ) : (
        <Flex sx={mergeDeepRight(styles.btnWrapper, sx)}>
          <Button
            sx={styles.btn}
            as={Link}
            to={url as string}
            target={type === AddToCartButtonType.BuyNow ? '_blank' : '_self'}
            variant="dark"
            aria-label={name}
          >
            {name}
          </Button>
        </Flex>
      )}
    </>
  )
}

export default SalePurchaseButton
