import gql from 'graphql-tag'
import { TApolloClient } from 'src/types/apollo'
import { pathOr } from 'ramda'
import { Cart, Order, ProcessCheckoutInput } from 'src/generated/graphql'
import { CheckoutResponse } from '@concepts/Checkout/types/Checkout'
import { parseOrder } from '@concepts/OrderConfirmation/repository/OrderRepository'
import CartDTO from '@concepts/Cart/dto/CartDTO'

const PROCESS_CHECKOUT_MUTATION = gql`
  mutation ProcessCheckout($input: ProcessCheckoutInput!) {
    processCheckout(input: $input) {
      success
      gtoken
      uidocp
      accountLocked

      order {
        databaseId
        priceInCents
        isAllFreebie

        user {
          databaseId
          email
          firstName
          lastName
          isGuest

          info {
            city
            country
            state
            dateOfBirth
            gender
            zip
            fastCheckout
            phoneNumber
          }

          addresses(last: 1) {
            nodes {
              countryCode
              city
              state
              zip
              phoneNumber
            }
          }
        }

        groupedLineItems {
          unitPriceInCents
          quantity

          lineItems {
            paidPriceInCents
          }

          sale {
            databaseId
            name
            slug

            type {
              name
            }
          }
        }

        publisher {
          databaseId
          hostname
        }

        currency {
          code
        }
      }

      cart {
        items {
          title
          source

          sale {
            databaseId
          }
        }
      }

      challenge {
        expires
        value
      }

      requiresAction {
        paymentIntentId
        paymentIntentClientSecret
      }

      errors {
        message
        path
      }
    }
  }
`

const complete = async (
  params: ProcessCheckoutInput,
  apolloClient: TApolloClient
): Promise<CheckoutResponse> =>
  apolloClient
    .mutate({
      mutation: PROCESS_CHECKOUT_MUTATION,
      variables: { input: params }
    })
    .then(pathOr({} as CheckoutResponse, ['data', 'processCheckout']))
    .then((result) => ({
      ...result,
      cart: result.cart && CartDTO.parse(result.cart as unknown as Cart),
      order: result.order && parseOrder(result.order as unknown as Order)
    }))

export default complete
