export const isYoutube = (url: string): boolean => {
  return /youtu\.?be/.test(url)
}

export const isVimeo = (url: string): boolean => {
  return /vimeo\.com/.test(url)
}

export const isWistia = (url: string): boolean => {
  return /wistia\.com/.test(url)
}

export const getYoutubeId = (url: string): string => {
  const isShortUrl = /youtu.be/.test(url)
  const isEmbed = /embed/.test(url)

  if (isShortUrl) return url.split('.be/')[1]
  if (isEmbed) return url.split('/embed/')[1]
  return url.split('watch?v=')[1]
}

export const getVimeoId = (url: string): string => {
  return url.split('vimeo.com/')[1]
}
