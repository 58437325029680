import Button from '@atoms/UIButton/Button'
import { Flex, Text } from '@lib/UIComponents'

type LoginOrCreateAccountProps = {
  text: string
  setLogin: Function
  setSignUp: Function
}

const LoginOrCreateAccount = ({
  text,
  setLogin,
  setSignUp
}: LoginOrCreateAccountProps) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: ['center', 'center', 'center', 'normal']
      }}
    >
      <Text as="p" sx={{ fontSize: [1, 2], mb: [3, 5] }}>
        {text}
      </Text>
      <Button
        variant="dark"
        size="large"
        sx={{
          maxWidth: ['400px', '400px', '400px', 'none']
        }}
        onClick={() => setLogin(true)}
      >
        Login
      </Button>
      <Flex
        sx={{
          mt: 7,
          fontSize: [1, 2],
          alignItems: 'center',
          justifyContent: ['center', 'normal']
        }}
      >
        <Text sx={{ mr: 2, color: 'grays.600' }}>Don't have one?</Text>
        <Button
          variant="link"
          sx={{
            padding: 0,
            minHeight: 'auto',
            color: 'black',
            fontWeight: 'bold',
            fontSize: [1, 2]
          }}
          onClick={() => setSignUp(true)}
        >
          Create Account
        </Button>
      </Flex>
    </Flex>
  )
}

export default LoginOrCreateAccount
