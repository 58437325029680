import segmentAnalytics from '@lib/segment/analytics'
import { SaleDetailsType } from '@concepts/Sales/types/sale'

type UseSaleTab = {
  dispatchAffiliateButtonAnalytics: (
    sale: SaleDetailsType,
    publisherId: number
  ) => void
}

export const useSaleTab = (): UseSaleTab => {
  const dispatchAffiliateButtonAnalytics = (
    sale: SaleDetailsType,
    publisherId: number
  ) => {
    if (sale.fromAffiliate) {
      segmentAnalytics.track('Affiliate Buy Button Mobile Clicked', {
        publisher_id: publisherId
      })
    }
  }

  return { dispatchAffiliateButtonAnalytics }
}
