import { SaleReviewsType } from '../../SaleReviews/types/saleReviews'
import { Media } from '@molecules/SaleGallery/SaleGallery'
import {
  User,
  Brand,
  Category,
  Product,
  Sale,
  SaleGroup,
  ProductTab,
  SaleGroupOption,
  SaleImageCollection,
  RecurringProp,
  ResponsiveImage
} from 'src/generated/graphql'
import { RecurringSaleGroupType } from '../dto/RecurringSaleGroupDTO'
import { SaleGroupType } from '../dto/SaleGroupDTO'

export type SaleReview = {
  comment: string
  rating: number
  status: string
  user: User
  reviewReason?: string
  createdAt?: string
  databaseId?: number
}

export type ProductType = Product & {
  medias: Media[]
  iconImage: ResponsiveImage
}
export type LeaderboardType = {
  rank: number
  name: string
  paidPriceCents: number
  avatarSrc: string
}

export type SpecialSaleBannerBg = {
  url: string
}

export type SaleDetailsType = {
  id: number
  name: string
  slug: string
  title: string
  customSalePageTitle: string
  metaDescription: string
  terms: string
  tabs: Array<Array<ProductTab>>
  priceInCents: number
  freeShippingMessage: string
  shippingMessage: string
  retailPriceInCents: number
  miniBundle: Array<SaleDetailsType>
  shippableCountries: Array<string>
  preDropPriceInCents: number
  priceToBeatAverageCents: number
  nyopMinimumToMiniCents: number
  nyopAveragePriceToken: string
  calculatedDiscount: number
  preDropPrice: boolean
  priceDescription: string
  priceDropDescription: string
  shipsFast: boolean
  ships: boolean
  interval: boolean
  expiresAt: string
  expired: boolean
  medias: Media[]
  group: SaleGroupType
  firstProduct: ProductType | null
  imageSrc: string | null
  maxLeadTime: number
  maxPerUser: number
  maxForCurrentUser: number
  maxAvailable: number
  soldOut: boolean
  imageCollection: SaleImageCollection | null
  products: Array<ProductType>
  isActive: boolean
  isPrivate: boolean
  noindex: boolean
  category: Category
  vendorName: string
  affiliateUrl: string
  fromAffiliate: boolean
  affiliatePriceText: string
  affiliateBuyButtonText: string
  brand: Brand | null
  saleWarranties: Sale[]
  saleGroup: SaleGroup
  saleGroupOption: SaleGroupOption
  priceValidUntil: string
  breadcrumb: Category[]
  shippingCostInCents: number
  specialSaleBannerBgUrl: string
  type: string
  numberOfCourses: number
  numberOfLessons: number
  totalHours: number
  numSold: number
  leaderboard: Array<LeaderboardType>
  recurringInterval: string
  isRecurringTrial: boolean
  recurringTrialPeriodDays: number
  recurringSaleIntervalPrice: number
  recurringSaleGroup: RecurringSaleGroupType
  recurringProps: RecurringProp[]
  useCourseLayout: boolean
  priceDropEndsAt: string
  saleReviews: SaleReviewsType
  activeAt?: string
  canonicalUrl: string
}

export type SaleDetailsProps = {
  sale: SaleDetailsType
}

export enum SaleTypeEnum {
  Solo = 'Solo',
  SoloVariant = 'Solo - Variant',
  Freebie = 'Freebie',
  Bundle = 'Bundle',
  BigBundle = 'Big Bundle',
  NYOPFull = 'NYOP - Full',
  NYOPMini = 'NYOP - Mini',
  StoreCredit = 'Store Credit',
  Recurring = 'Recurring Sale',
  Warranty = 'Warranty'
}
