import { isEmpty, mergeDeepRight } from 'ramda'
import { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore from 'swiper'
import { Navigation, Pagination, Thumbs } from 'swiper/modules'

import Button from '@atoms/UIButton/Button'
import Picture from '@atoms/UIPicture/Picture'
import MediaSelector from '@molecules/MediaSelector/MediaSelector'
import { useVideo } from '@molecules/MediaSelector/utils/useVideo'

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CirclePlayIcon
} from '@atoms/Icon/Icon'
import { displayMediaThumb } from './Utils/DisplayThumbnails'
import { Box } from '@lib/UIComponents'

export type Media = {
  thumb: string
  url: string
  playing?: boolean
}

type Props = {
  mediaList: Media[]
  saleName: string
  sx?: object
}

export const styles = {
  container: {
    mb: [8, 9, 9, 0, 0]
  },
  mainGallery: {
    '.swiper': {
      zIndex: 0,
      pb: '35px'
    },
    '.swiper-pagination-bullets': {
      display: ['flex', 'flex', 'flex', 'none'],
      justifyContent: 'center',
      alignItems: 'center'
    },
    '.swiper-pagination-bullet': {
      width: '6px',
      height: '6px',
      margin: '0 6px',
      opacity: 1,
      backgroundColor: 'grays.140'
    },
    '.swiper-pagination-bullet-active': {
      width: '12px',
      height: '12px',
      backgroundColor: 'blacks.500'
    }
  },
  galleryPicture: {
    display: 'block',
    width: '100%',
    borderRadius: 'default'
  },
  thumbnailsContainer: {
    mt: [8, 8, '42px', 8],
    display: ['none', 'none', 'none', 'flex'],
    justifyContent: 'center',
    alignItems: 'center',
    '.swiper': {
      zIndex: 0,
      display: 'inline-flex',
      margin: 0
    },
    '.swiper-slide': {
      maxWidth: '68px',
      minWidth: '68px',
      height: '68px',
      border: `2px solid transparent`,
      borderRadius: 'default',
      overflow: 'hidden',
      cursor: 'pointer',
      '&:last-child': {
        mr: '0 !important'
      }
    },
    '.swiper-slide-thumb-active': {
      border: '2px',
      borderStyle: 'solid',
      borderColor: 'primary'
    }
  },
  thumbWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    svg: {
      fontSize: '68px',
      display: 'block',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  },
  thumbnail: {
    display: 'block',
    objectFit: 'cover',
    height: '100%'
  },
  navArrows: {
    backgroundColor: 'transparent',
    border: 0,
    color: 'blacks.500',
    svg: {
      height: '24px'
    },
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}

const SaleGallery = ({ mediaList, saleName, sx = {} }: Props) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore>()

  const { prevRef, nextRef, medias, onSlideChange, pauseVideo, playVideo } =
    useVideo(mediaList)

  if (isEmpty(mediaList)) return null

  return (
    <Box sx={mergeDeepRight(styles.container, sx)}>
      <Box sx={styles.mainGallery}>
        <Swiper
          modules={[Navigation, Thumbs, Pagination]}
          autoHeight
          spaceBetween={10}
          onBeforeTransitionStart={onSlideChange}
          thumbs={{ swiper: thumbsSwiper }}
          pagination
          breakpoints={{
            1025: {
              allowTouchMove: false
            }
          }}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current
          }}
        >
          {medias.map((media: Media, index: number) => (
            <SwiperSlide key={index} data-testid="gallerySlide">
              <MediaSelector
                media={media}
                saleName={saleName}
                dataSrc={media.url}
                pictureStyles={styles.galleryPicture}
                index={index}
                playVideo={() => playVideo(index)}
                pauseVideo={
                  /* istanbul ignore next */
                  () => pauseVideo(index)
                }
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>

      {mediaList.length > 1 && (
        <Box sx={styles.thumbnailsContainer}>
          <Button
            ref={prevRef}
            sx={styles.navArrows}
            data-testid="prevSlide"
            aria-label="Previous Slide"
            alt="Previous Slide"
          >
            <ChevronLeftIcon />
          </Button>

          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={16}
            slidesPerView="auto"
            slidesPerGroup={1}
            threshold={10}
            watchSlidesProgress
          >
            {medias.map((media: Media, index: number) => (
              <SwiperSlide key={index} data-testid="thumbnailSlide">
                <Box sx={styles.thumbWrapper}>
                  <Picture
                    sx={styles.thumbnail}
                    src={displayMediaThumb(
                      media.thumb ? media.thumb : media.url
                    )}
                    alt={saleName}
                  />
                  {Object.prototype.hasOwnProperty.call(media, 'playing') && (
                    <CirclePlayIcon data-testid="playIcon" />
                  )}
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>

          <Button
            ref={nextRef}
            sx={styles.navArrows}
            data-testid="nextSlide"
            aria-label="Next Slide"
            alt="Next Slide"
          >
            <ChevronRightIcon />
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default SaleGallery
