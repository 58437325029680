import gql from 'graphql-tag'

import { TApolloClient } from 'src/types/apollo'
import { PurchaseButton } from 'src/generated/graphql'

const SALE_PURCHASE_BUTTON_QUERY = gql`
  query PurchaseButton($saleSlug: String!) {
    purchaseButton(saleSlug: $saleSlug) {
      name
      type
      url
    }
  }
`

const find = async (
  saleSlug: string,
  apolloClient: TApolloClient
): Promise<PurchaseButton> => {
  const { data } = await apolloClient.query({
    query: SALE_PURCHASE_BUTTON_QUERY,
    variables: { saleSlug },
    fetchPolicy: 'cache-first'
  })

  return data.purchaseButton
}

export default { find }
