import { mergeDeepRight } from 'ramda'

import Currency from '@atoms/UICurrency/Currency'
import { SaleTypeEnum } from '@concepts/Sales/types/sale'
import { Flex, Text } from '@lib/UIComponents'
import { SaleSummaryStyle } from './utils'

type PriceSummaryProps = {
  variation: SaleSummaryStyle
  fromAffiliate: boolean
  affiliatePriceText: string
  soldOut: boolean
  priceInCents: number
  retailPriceInCents: number
  type: string
  preDropPrice: boolean
  preDropPriceInCents: number
  placedOnImageBackground: boolean
}

const isPreDropPrice = (
  preDropPrice: boolean,
  preDropPriceInCents: number,
  priceInCents: number,
  variation: SaleSummaryStyle
) => {
  if (!preDropPrice) return false

  const isSoloSale = variation === SaleSummaryStyle.Solo
  const hasDiscount = priceInCents < preDropPriceInCents

  return isSoloSale && hasDiscount
}

const PriceSummary = ({
  variation,
  fromAffiliate,
  affiliatePriceText,
  soldOut,
  priceInCents,
  retailPriceInCents,
  type,
  preDropPrice,
  preDropPriceInCents,
  placedOnImageBackground
}: PriceSummaryProps) => {
  const getStyles = (variation: SaleSummaryStyle) => {
    const soloStyles = {
      priceInfoWrapper: {
        textAlign: 'left',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'center'
      },
      promotionPrice: {
        textAlign: 'left',
        mr: 3,
        width: 'auto',
        fontSize: [3, 3, 3, 5]
      },
      retailPrice: {
        fontSize: [3, 3, 3, 5],
        textDecoration: 'line-through'
      },
      priceDropBox: {
        color: 'danger',
        width: 'auto',
        flex: 'none',
        mr: [0, 3, 3, 3],
        mt: [1, 2],
        span: {
          fontSize: 1,
          whiteSpace: 'nowrap'
        }
      },
      soldOut: {
        color: 'grays.400',
        fontSize: [6, 6],
        flex: '100%'
      }
    }

    const bundleStyles = {
      priceInfoWrapper: {
        alignItems: 'center',
        width: '100%',
        textAlign: ['left', 'left', 'left', 'right'],
        justifyContent: ['flex-start', 'flex-start', 'flex-start', 'flex-end']
      },
      promotionPrice: {
        flex: ['none', 'none', 'none', '1', '1']
      },
      priceDropBox: {
        flex: ['none', 'none', 'none', '1', '1']
      }
    }

    switch (variation) {
      case SaleSummaryStyle.Bundle:
        return mergeDeepRight(soloStyles, bundleStyles)

      default:
        return soloStyles
    }
  }
  const applyRetailPrice = priceInCents < retailPriceInCents

  const checkedPreDropPrice = isPreDropPrice(
    preDropPrice,
    preDropPriceInCents,
    priceInCents,
    variation
  )

  const isRecurringSale = type === SaleTypeEnum.Recurring
  const isGiveaway = variation === SaleSummaryStyle.Giveaway

  const styles = getStyles(variation)

  if (fromAffiliate && affiliatePriceText !== '') {
    return (
      <Flex sx={styles.priceInfoWrapper}>
        <Text sx={styles.promotionPrice}> {affiliatePriceText} </Text>
      </Flex>
    )
  }

  return (
    <Flex sx={styles.priceInfoWrapper}>
      {soldOut && <Text sx={styles.soldOut}>Sold Out</Text>}

      {priceInCents === 0 && (
        <Text sx={{ fontSize: [3, 3, 3, 5], mr: 2 }}>
          {isGiveaway ? 'Free Entry' : 'Free'}
        </Text>
      )}

      {isRecurringSale && (
        <Text
          sx={{
            fontSize: checkedPreDropPrice ? [3, 3, 3, 4] : [3, 3, 3, 5],
            display: ['none', 'block']
          }}
        >
          starting at&nbsp;
        </Text>
      )}

      <Currency
        sx={{
          ...styles.promotionPrice,
          color: checkedPreDropPrice ? 'danger' : ''
        }}
        monthly={isRecurringSale}
      >
        {priceInCents}
      </Currency>

      {applyRetailPrice && (
        <Currency
          sx={{
            ...styles.retailPrice,
            color: placedOnImageBackground ? 'grays.150' : 'grays.400',
            ml: 3
          }}
          monthly={isRecurringSale}
        >
          {retailPriceInCents}
        </Currency>
      )}
    </Flex>
  )
}

export default PriceSummary
