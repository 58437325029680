import { Box } from 'rebass'

type Props = {
  amount: number
  textColor?: string
}

/*
 * Render PayIn4Message on pages that don't use PayPal Buttons will require you
 * to invoke "useScript" explicitly here.
 */
const PayIn4Message: React.FC<React.PropsWithChildren<Props>> = ({
  amount,
  textColor
}) => (
  <Box
    data-testid="pay-in-4-message"
    data-pp-message
    data-pp-style-text-color={textColor ?? null}
    data-pp-amount={amount}
  />
)

export default PayIn4Message
