import { CountdownClock } from '@atoms/CountdownClock/CountdownClock'
import { SaleSummaryStyle } from '@molecules/SaleSummary/utils'
import { useTimeLeft } from './hooks/useTimeLeft'
import { Text } from '@lib/UIComponents'

type TimeLeftProps = {
  expiresAt: string
  startTime?: Date
  saleVariation: SaleSummaryStyle
  priceDropEndsAt: string
  messageStyles: object
  remainingHoursCountdown?: number
}

const GenericMessage = ({ messageStyles }: { messageStyles: object }) => {
  return <Text sx={{ ...messageStyles }}>Expires Soon!</Text>
}

const TimeLeft = ({
  priceDropEndsAt,
  saleVariation,
  expiresAt,
  messageStyles,
  startTime,
  remainingHoursCountdown = 48
}: TimeLeftProps) => {
  const {
    message,
    showCountdown,
    distanceInDays,
    showGenericMessage,
    countdownEndsAt
  } = useTimeLeft({
    priceDropEndsAt,
    saleVariation,
    endDate: new Date(expiresAt),
    startTime,
    remainingHoursCountdown
  })

  if (!message) return <></>

  if (showGenericMessage)
    return <GenericMessage messageStyles={messageStyles} />

  return (
    <Text
      sx={{
        fontSize: [1, 2],
        ...messageStyles
      }}
    >
      {message}{' '}
      {showCountdown ? (
        <CountdownClock sx={{ ...messageStyles }} endDate={countdownEndsAt} />
      ) : (
        <Text sx={{ ...messageStyles, fontSize: [2, 3] }}>
          {distanceInDays}
        </Text>
      )}
    </Text>
  )
}

export { TimeLeft }
