import { mergeDeepRight } from 'ramda'

import { Box, Text } from '@lib/UIComponents'
import { UIStyles } from '@lib/UIComponents/types'
import { headings } from 'src/components/theme/heading'

type ListWithHorizontalTitleProps = {
  title: string
  responsiveHeight?: number
  sx?: UIStyles
  children: React.ReactNode
}

const styles = {
  wrapper: {
    display: ['block', 'block', 'grid'],
    py: [3, 3, 12],
    px: [2, 2, 12],
    ...headings,
    gridTemplateColumns: '1fr 3fr'
  },
  title: {
    fontWeight: 'heading',
    fontSize: 4,
    color: 'blacks.500'
  },
  column: {
    py: [3, 3, 2]
  },
  content: {
    fontSize: 2,
    color: 'blacks.500',
    'h1, h2, h3, h4, h5, h6': {
      mt: [4, 6],
      mb: [4, 6]
    },
    'ol, ul': {
      pb: [5, 5, 0],
      pl: [5],
      pr: [2],
      m: 0
    },
    p: {
      wordBreak: 'break-word',

      '&:first-of-type': {
        marginTop: 0,
        paddingTop: 0
      }
    },
    a: {
      color: 'primary',
      transition: 'opacity 250ms ease-in-out',
      '&:hover': {
        opacity: 0.8
      }
    }
  }
}

const ListWithHorizontalTitle = ({
  title,
  children,
  sx = {}
}: ListWithHorizontalTitleProps) => (
  <Box sx={mergeDeepRight(styles.wrapper, sx as object)}>
    <Text as="h2" sx={styles.title}>
      {title}
    </Text>
    <Box sx={styles.column}>
      <Box sx={styles.content}>{children}</Box>
    </Box>
  </Box>
)

export default ListWithHorizontalTitle
