import Button from '@atoms/UIButton/Button'
import { useAuthContext } from '@concepts/Auth/store/context'
import EmailAlreadyUsedAlert from '@concepts/Auth/views/EmailAlreadyUsedAlert'
import LoginForm from '@concepts/Auth/views/Login/LoginForm'
import SignUpForm from '@concepts/Auth/views/SignUp/SignUpForm'
import { Flex, Text } from '@lib/UIComponents'

type Props = {
  onSubmit: Function
  returnTo: string
  signUpFirst?: boolean
  title?: boolean
  onClickGuestChange?: Function
}

const UserAuth = ({
  onSubmit,
  returnTo,
  signUpFirst = false,
  title = false,
  onClickGuestChange
}: Props) => {
  const [authState, { clearUserEmailRegisteredState }] = useAuthContext()

  const handleIsGuestChange = () => {
    clearUserEmailRegisteredState()
    onClickGuestChange?.()
  }

  return (
    <>
      {signUpFirst && authState.userEmailRegistered && (
        <EmailAlreadyUsedAlert
          publisherName={String(authState.publisherName)}
          signUpDate={String(authState.signUpDate)}
        />
      )}
      {signUpFirst ? (
        <SignUpForm onSubmit={onSubmit} returnTo={returnTo} title={title} />
      ) : (
        <LoginForm onSubmit={onSubmit} returnTo={returnTo} title={title} alt />
      )}
      <Flex
        sx={{
          fontSize: [1, 2],
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          mt: 7
        }}
      >
        <Text sx={{ color: 'grays.600', mr: 2 }}>
          {signUpFirst ? 'Already Have An Account?' : 'New Here?'}
        </Text>
        <Button
          variant="link"
          onClick={handleIsGuestChange}
          sx={{
            textDecoration: 'underline',
            padding: 0,
            minHeight: 'auto',
            color: 'black',
            fontWeight: 'bold',
            fontSize: [1, 2]
          }}
        >
          {signUpFirst ? 'Login' : 'Create Account'}
        </Button>
      </Flex>
    </>
  )
}

export default UserAuth
