import { useEffect } from 'react'

import { CardSize } from '@molecules/InlineCard/InlineCard'
import { RecentlyViewedType } from '../types/recentlyViewedTypes'
import InlineCardList from '@molecules/InlineCardList/InlineCardList'
import ecommerceAnalytics from '@lib/gtm/events/ecommerce'
import { isClient } from '@utils/env'

type Props = {
  sales: RecentlyViewedType[]
}

const RecentlyViewedSales: React.FC<React.PropsWithChildren<Props>> = ({
  sales
}) => {
  useEffect(() => {
    /* istanbul ignore else */
    if (isClient()) {
      ecommerceAnalytics.trackImpression(sales, 'module Recently Viewed')
    }
  }, [sales])

  return (
    <InlineCardList
      title="Recently Viewed"
      items={sales}
      size={CardSize.MEDIUM}
    />
  )
}

export default RecentlyViewedSales
