import { useRouter } from 'next/router'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useAuthApollo } from '@lib/apollo'

import { AddToCartContext } from './addToCartContext'
import { AddToCartButtonType } from 'src/generated/graphql'
import { useAuthContext } from '@concepts/Auth/store/context'
import PurchaseButtonRepository from '../repository/PurchaseButtonRepository'

const Provider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const router = useRouter()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoadingBtn, setIsLoadingBtn] = useState(true)
  const [{ user, loading }] = useAuthContext()
  const apolloClient = useAuthApollo()
  const [purchaseButton, setPurchaseButton] = useState({
    name: 'Add To Cart',
    type: AddToCartButtonType.AddToCart
  })

  const apolloRef = useRef(apolloClient)
  apolloRef.current = apolloClient

  useEffect(() => {
    let ignore = false

    // adopted pattern https://react.dev/learn/synchronizing-with-effects#fetching-data
    if (!loading) {
      PurchaseButtonRepository.find(
        router.query.slug as string,
        apolloRef.current
      )
        .then((data) => {
          if (ignore) return
          setPurchaseButton(data)
        })
        .finally(() => {
          if (ignore) return
          setIsLoadingBtn(false)
        })
    }

    return () => {
      ignore = true
    }
  }, [router.query.slug, user, loading])

  const value = useMemo(() => {
    return {
      isSubmitting,
      setIsSubmitting,
      purchaseButton,
      isLoadingBtn,
      setIsLoadingBtn
    }
  }, [isLoadingBtn, isSubmitting, purchaseButton])

  return (
    <AddToCartContext.Provider value={value}>
      {children}
    </AddToCartContext.Provider>
  )
}

export default Provider
