import { ChangeEventHandler, MouseEventHandler } from 'react'
import { mergeDeepRight } from 'ramda'

import { MinusIcon, PlusIcon } from '@atoms/Icon/Icon'
import useAddSaleToCart from './hooks/useAddSaleToCart'
import { SaleDetailsType } from '@concepts/Sales/types/sale'
import PaypalSaleButton from '@concepts/Paypal/views/PaypalSaleButton/PaypalSaleButton'
import { CartItemSource } from 'src/generated/graphql'
import { Box, Icon, Input, Text } from '@lib/UIComponents'
import { UIStyles } from '@lib/UIComponents/types'
import Button from '@atoms/UIButton/Button'

type AddSaleToCartProps = {
  sale: SaleDetailsType
  warrantyId: number
  text: string
  isSubmitting: boolean
  bidPrice?: number
  showQuantity?: boolean
  showPaypal?: boolean
  sx?: UIStyles
  paypalSx?: UIStyles
  placedOnImageBackground?: boolean
}

const AddSaleToCart = ({
  sale,
  warrantyId,
  bidPrice,
  text,
  isSubmitting,
  showQuantity = true,
  showPaypal = true,
  sx = {},
  paypalSx = {},
  placedOnImageBackground = false
}: AddSaleToCartProps) => {
  const {
    addToCart,
    decrease,
    increase,
    onChangeInput,
    quantity,
    quantityLeft
  } = useAddSaleToCart({ sale })

  const isQuantityInvalid = !quantityLeft || quantity > quantityLeft
  const warningMessage = `Cannot exceed the maximum quantity of ${sale.maxForCurrentUser} for this product`

  return (
    <>
      <Box
        sx={mergeDeepRight(
          {
            display: 'flex',
            flexWrap: 'wrap',
            mb: 7,
            minWidth: ['auto', '417px']
          },
          sx as object
        )}
      >
        {showQuantity && (
          <Box
            sx={{
              display: 'inline-flex',
              alignItems: 'stretch',
              border: '1px solid',
              borderColor: 'grays.150',
              borderRadius: 'circle',
              overflow: 'hidden',
              maxWidth: ['105px', '120px'],
              width: '100%',
              height: '40px',
              mr: 3
            }}
          >
            <Button
              sx={{
                flex: '0 0 30%',
                fontSize: '11px',
                color: placedOnImageBackground ? 'white' : '',
                padding: 0,
                backgroundColor: 'transparent',
                border: 'none !important',
                justifyContent: 'center',
                alignItems: 'center',
                transition: '200ms ease',
                '&:hover': {
                  backgroundColor: 'transparent',
                  svg: {
                    color: 'primary'
                  }
                }
              }}
              aria-label="Decrease"
              disabled={quantity <= 1}
              onClick={decrease as MouseEventHandler<HTMLButtonElement>}
            >
              <Icon as={MinusIcon} sx={{ '&:hover': { color: 'primary' } }} />
            </Button>

            <Input
              data-testid="quantity"
              aria-label="Quantity"
              sx={{
                border: 0,
                flex: '1 1 25%',
                padding: 0,
                pb: 1,
                textAlign: 'center',
                mozAppearance: 'textfield',
                '&::webkitInnerSpinButton': {
                  webkitAppearance: 'none'
                },
                '&:focus-visible': {
                  border: 0,
                  outline: 'none'
                }
              }}
              value={quantity}
              maxLength={quantityLeft.toString().length}
              onChange={onChangeInput as ChangeEventHandler<HTMLInputElement>}
            />

            <Button
              sx={{
                flex: '0 0 30%',
                fontSize: '11px',
                color: placedOnImageBackground ? 'white' : '',
                padding: 0,
                backgroundColor: 'transparent',
                border: 0,
                justifyContent: 'center',
                alignItems: 'center',
                transition: '200ms ease',
                '&:hover': {
                  backgroundColor: 'transparent',
                  svg: {
                    color: 'primary'
                  }
                }
              }}
              aria-label="Increase"
              disabled={quantity >= quantityLeft}
              onClick={increase as MouseEventHandler<HTMLButtonElement>}
            >
              <Icon as={PlusIcon} />
            </Button>
          </Box>
        )}

        <Button
          variant="primary"
          sx={{
            flex: '1 0 auto',
            justifyContent: 'center',
            maxWidth: '285px',
            height: '40px'
          }}
          aria-label={text}
          disabled={isQuantityInvalid || isSubmitting}
          onClick={() =>
            addToCart({
              warranty: warrantyId,
              bid: bidPrice,
              source: CartItemSource.Pdp
            })
          }
        >
          {text}
        </Button>

        {isQuantityInvalid && (
          <Text
            as="p"
            sx={{
              color: 'danger',
              flex: '100%',
              fontSize: 1,
              mt: 4,
              h: 4,
              w: 20
            }}
          >
            {warningMessage}
          </Text>
        )}
      </Box>

      {showPaypal && (
        <PaypalSaleButton
          sx={mergeDeepRight(
            {
              mt: -3
            },
            paypalSx as object
          )}
          sale={sale}
          warrantyId={warrantyId}
          quantity={quantity}
          bidPrice={bidPrice}
          placedOnImageBackground={placedOnImageBackground}
        />
      )}
    </>
  )
}

export default AddSaleToCart
