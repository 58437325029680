import { useState } from 'react'
import { mergeDeepRight } from 'ramda'

import Button from '@atoms/UIButton/Button'
import useSharingLinks from './hooks/useSharingLinks'
import {
  CheckIcon,
  CopyIcon,
  FacebookIcon,
  TwitterIcon
} from '@atoms/Icon/Icon'
import { Box, Text } from '@lib/UIComponents'
import { UIStyles } from '@lib/UIComponents/types'

type SharingLinkProps = {
  title?: string
  url?: string
  hideButtonsTitle?: boolean
  successOnClick?: boolean
  beforeClick?: Function
  urlParams?: object
  sx?: UIStyles
}

type SharingLinkButton = {
  id: string
  label: string
  shareMethod: Function
  icon: JSX.Element
  clicked: boolean
}

const getStyles = (hideButtonsTitle: boolean) => ({
  container: {
    display: 'inline-grid',
    gridTemplateColumns: hideButtonsTitle ? '1fr 1fr 1fr' : ['1fr', '1fr 1fr'],
    width: hideButtonsTitle ? 'auto' : '100%',
    gap: 4
  },
  btn: (clicked: boolean) => ({
    backgroundColor: clicked ? 'greens.0' : 'white',
    color: clicked ? 'greens.600' : 'black',
    minWidth: '60px',
    height: '50px',
    border: '1px solid',
    borderColor: clicked ? 'greens.0' : 'grays.150',
    borderRadius: 'default',
    justifyContent: hideButtonsTitle ? 'center' : 'flex-start',
    alignItems: 'center',
    transition: '200ms ease-in-out',
    '&:hover': {
      borderColor: clicked ? 'greens.0' : 'grays.150',
      transform: clicked ? 'scale(1)' : 'scale(0.975)',
      color: clicked ? 'greens.600' : 'black'
    }
  }),
  btnTitle: {
    ml: [-4, 2],
    flex: 1
  }
})

const SharingLinks = ({
  url = '',
  title = '',
  beforeClick,
  hideButtonsTitle = true,
  successOnClick = false,
  urlParams,
  sx = {}
}: SharingLinkProps) => {
  const { shareOnFacebook, shareOnTwitter, copyUrl } = useSharingLinks({
    url,
    title,
    urlParams
  })
  const [buttons, setButtons] = useState<SharingLinkButton[]>([
    {
      id: 'facebook',
      label: 'Share on Facebook',
      shareMethod: shareOnFacebook,
      icon: <FacebookIcon />,
      clicked: false
    },
    {
      id: 'twitter',
      label: 'Share on Twitter',
      shareMethod: shareOnTwitter,
      icon: <TwitterIcon />,
      clicked: false
    },
    {
      id: 'copy',
      label: 'Copy Link',
      shareMethod: copyUrl,
      icon: <CopyIcon />,
      clicked: false
    }
  ])

  const styles = getStyles(hideButtonsTitle)

  const share = (button: SharingLinkButton) => {
    if (beforeClick) beforeClick()

    button.shareMethod()

    if (successOnClick) {
      const newButtons = buttons.map((btn) => {
        if (btn.id !== button.id) {
          return btn
        }

        return {
          ...btn,
          clicked: true
        }
      })

      setButtons(newButtons)
    }
  }

  return (
    <Box sx={mergeDeepRight(styles.container, sx as object)}>
      {buttons.map((button) => (
        <Button
          key={button.id}
          sx={styles.btn(button.clicked)}
          aria-label={button.label}
          onClick={() => share(button)}
        >
          {button.clicked ? (
            <CheckIcon data-testid="shareCheck" />
          ) : (
            button.icon
          )}

          {!hideButtonsTitle && (
            <Text sx={styles.btnTitle}>{button.label}</Text>
          )}
        </Button>
      ))}
    </Box>
  )
}

export default SharingLinks
