import { Box } from '@lib/UIComponents'
import { mergeDeepRight } from 'ramda'

const styles = { width: '100%', height: '1px', bg: 'grays.100' }

type SingleLineSeparatorProps = { sx?: object }

const SingleLineSeparator = ({ ...props }: SingleLineSeparatorProps) => (
  <Box sx={mergeDeepRight(styles, props.sx || {})} />
)

export default SingleLineSeparator
