import { MouseEventHandler } from 'react'

import Button from '@atoms/UIButton/Button'
import SharingLinks from '@molecules/SharingLinks/SharingLinks'
import { Box, Text } from '@lib/UIComponents'

type Props = {
  title: string
  btnOnClick?: Function
  disableButton?: boolean
  beforeShareClick?: Function
  sx?: object
}

const FreebiesHeader = ({
  title,
  btnOnClick,
  disableButton = false,
  beforeShareClick
}: Props) => {
  return (
    <Box sx={{ mb: 6 }}>
      <Text as="p" sx={{ mb: 4 }}>
        Share to your Facebook or Twitter account to add this freebie to your
        cart.
      </Text>

      <SharingLinks
        title={title as string}
        beforeClick={beforeShareClick}
        hideButtonsTitle={false}
        successOnClick
        sx={{
          mb: 6
        }}
      />

      <Button
        block
        variant="dark"
        sx={{
          height: '40px',
          justifyContent: 'center'
        }}
        disabled={disableButton}
        onClick={btnOnClick as MouseEventHandler}
      >
        Get it
      </Button>
    </Box>
  )
}

export default FreebiesHeader
