import ReactPlayer from 'react-player'
import { isEmpty, mergeDeepRight } from 'ramda'

import Picture from '@atoms/Picture/Picture'
import { CirclePlayIcon } from '@atoms/Icon/Icon'
import { useVideoFrame } from './hooks/useVideoFrame'
import { Box } from '@lib/UIComponents'
import { UIStyles } from '@lib/UIComponents/types'

const styles = {
  container: {
    pt: '56.25%',
    position: 'relative',
    '.video-frame': {
      position: 'absolute',
      top: 0,
      left: 0
    }
  },
  thumbWrapper: (playing: boolean) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    visibility: playing ? 'hidden' : 'visible',
    opacity: playing ? 0 : 1,
    pointerEvents: playing ? 'none' : 'all',
    transition: playing ? 'none' : '1s ease 500ms',
    svg: {
      fontSize: ['96px', '96px', '144px', '202px'],
      display: 'block',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  }),
  thumbnail: {
    display: 'block',
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  }
}

export type VideoFrameProps = {
  url: string
  playing: boolean
  onPause: VoidFunction
  onPlay: VoidFunction
  sx?: UIStyles
}

const VideoFrame = ({
  url,
  playing,
  onPause,
  onPlay,
  sx = {}
}: VideoFrameProps) => {
  const { thumb, isClient, getVideoThumb, videoRef } = useVideoFrame({ url })
  if (isEmpty(url)) return null

  return (
    <Box sx={mergeDeepRight(styles.container, sx) as UIStyles}>
      <Box
        sx={styles.thumbWrapper(playing) as UIStyles}
        onClick={onPlay}
        data-testid="playTrigger"
      >
        {thumb && (
          <Picture sx={styles.thumbnail} src={thumb} alt="Video Thumbnail" />
        )}
        <CirclePlayIcon data-testid="playIcon" />
      </Box>
      {isClient && (
        <ReactPlayer
          controls
          url={url}
          className="video-frame"
          data-testid="videoFrame"
          ref={videoRef}
          onReady={getVideoThumb}
          playing={playing}
          onPlay={onPlay}
          onPause={onPause}
          width="100%"
          height="100%"
        />
      )}
    </Box>
  )
}

export default VideoFrame
