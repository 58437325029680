import { useRef, useState, useCallback, useEffect } from 'react'
import { isVimeo, isWistia, isYoutube } from '../Utils/video'
import {
  getVimeoThumb,
  getWistiaThumb,
  getYoutubeThumb
} from '../Utils/GetVideosThumbnails'

type Props = {
  url: string
}

const useVideoFrame = ({ url }: Props) => {
  const videoRef = useRef(null)
  const [isClient, setIsClient] = useState(false)
  const [thumb, setThumb] = useState('')

  /* istanbul ignore next */
  const getVideoThumb = useCallback(async () => {
    const fetchThumb = async () => {
      switch (true) {
        case isYoutube(url):
          return getYoutubeThumb(url)
        case isWistia(url):
          return getWistiaThumb(videoRef)
        case isVimeo(url):
          return await getVimeoThumb(url)
        default:
          return ''
      }
    }

    fetchThumb().then((data) => {
      setThumb(data)
    })
  }, [url])

  useEffect(() => {
    setIsClient(true)
  }, [])

  return { isClient, videoRef, getVideoThumb, thumb }
}
export { useVideoFrame }
