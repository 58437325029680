import { useRouter } from 'next/router'
import { mergeDeepRight } from 'ramda'

import { getQueryString } from '@utils/url'
import { usePublisherContext } from '@concepts/Publisher/store/context'
import { centralizeX, centralizeY } from '../utils/centralizeShareDialog'

export type OpenDialogType = {
  baseUrl: string
  width: number
  height: number
}

export type UseSharingLinkProps = {
  url?: string
  title?: string
  urlParams?: object
}

type useSharingLinksReturnType = {
  shareOnTwitter: Function
  shareOnFacebook: Function
  copyUrl: Function
}

const useSharingLinks = ({
  url,
  title,
  urlParams = { slug: null }
}: UseSharingLinkProps): useSharingLinksReturnType => {
  const { asPath, query } = useRouter()
  const { twitterHandle, hostname } = usePublisherContext()

  const getSharingUrl = () => {
    const newPath = asPath.split('?')[0]

    const params = getQueryString(mergeDeepRight(query as object, urlParams))

    return url || hostname + newPath + params
  }

  const shareOnFacebook = () => {
    const baseUrl = `https://www.facebook.com/sharer/sharer.php?u=${getSharingUrl()}`

    openShareDialog({ baseUrl, width: 555, height: 745 })
  }

  const shareOnTwitter = () => {
    const encodedTitle = encodeURI(title || '')
    const baseUrl = `https://twitter.com/intent/tweet?text=${encodedTitle}&url=${getSharingUrl()}${
      twitterHandle ? '&via=' + twitterHandle : ''
    }`

    openShareDialog({ baseUrl, width: 650, height: 650 })
  }

  const openShareDialog = ({ baseUrl, width, height }: OpenDialogType) => {
    const settings = `top=${centralizeY(height)},left=${centralizeX(
      width
    )},width=${width}px,height=${height}px`

    window.open(baseUrl, '_blank', settings)
  }

  const copyUrl = () => {
    navigator.clipboard.writeText(getSharingUrl())
  }

  return {
    shareOnTwitter,
    shareOnFacebook,
    copyUrl
  }
}

export default useSharingLinks
