import { pathOr } from 'ramda'
import { Maybe } from 'graphql/jsutils/Maybe'

import {
  Category,
  Product,
  ProductImageCollection,
  Sale
} from 'src/generated/graphql'
import { RecentlyViewedType } from '../types/recentlyViewedTypes'

const getImage = (imageCollection: Maybe<ProductImageCollection>) => {
  return pathOr(null, ['productShots', 0, 'url'], imageCollection) as Maybe<
    string | undefined
  >
}

const parse = (sales: Sale[]): RecentlyViewedType[] => {
  return sales.map((sale) => {
    const product = pathOr(
      {},
      ['products', 'nodes', 0],
      sale
    ) as unknown as Product

    return {
      id: sale.databaseId as number,
      databaseId: sale.databaseId as number,
      title: sale.name,
      slug: sale.slug,
      priceInCents: sale.priceInCents,
      categories: [sale.category as Category],
      imgUrl: getImage(product.imageCollection) as string
    }
  })
}

export default { parse }
