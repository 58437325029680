import gql from 'graphql-tag'
import { pathOr, pipe, map } from 'ramda'
import { TApolloClient } from 'src/types/apollo'
import { Order as GqlOrder, GroupedOrderLineItem } from 'src/generated/graphql'
import type { Order, OrderLineItem } from '../types/order'

const ORDER_CONFIRMATION_QUERY = gql`
  query OrderComplete($orderId: Int!) {
    viewer {
      ... on User {
        orders(id: $orderId) {
          upsellCoupon {
            percentageDiscount
          }

          edges {
            displayUpsells

            node {
              databaseId
              priceInCents
              creditsInCents
              discountInCents
              subtotalInCents
              taxInCents
              shippingCostInCents
              shippable
              processingFeeInCents
              processingFeeApplicable
              internationalShipping
              firstName
              lastName
              isAllFreebie

              coupon {
                code
              }

              paymentMethod {
                gateway
                creditCardType
                creditCardLast4
              }

              user {
                databaseId
                email
                firstName
                lastName
                isGuest
              }

              shippingAddress {
                firstName
                lastName
                address1
                address2
                city
                state
                zip
                countryCode
                countryName
                phoneNumber
              }

              billingAddress {
                firstName
                lastName
                address1
                address2
                city
                state
                zip
                countryCode
                countryName
                phoneNumber
              }

              currency {
                name
                code
              }

              groupedLineItems {
                unitPriceInCents
                quantity
                expectedDelivery

                sale {
                  databaseId
                  name
                  slug
                  isRedeemable
                  recurringTrialPeriodDays
                  isRecurringTrial
                  minLeadTime
                  maxLeadTime

                  type {
                    name
                  }

                  category {
                    name
                  }

                  imageCollection {
                    showcaseImage {
                      pictureAttributes
                    }
                  }

                  shareLink {
                    twitter
                    facebook
                    email
                  }
                }
              }

              publisher {
                databaseId
                code
                name
                hostname
                integrationsSetting {
                  resellerRatingsPopup
                  siteJabberPopup
                  trustPilotPopup
                  trustPilotUniqueLinkSecret(orderId: $orderId)
                  fluentPopup
                  fluentToken
                }
              }
            }
          }
        }
      }
    }
  }
`

const toLineItem = (lineItem: GroupedOrderLineItem) => ({
  quantity: lineItem.quantity,
  unitPriceInCents: lineItem.unitPriceInCents,
  expectedDelivery: lineItem.expectedDelivery,
  ...lineItem.sale
})

export const parseOrder = (
  order: GqlOrder,
  upsellDiscount?: number,
  displayUpsells?: boolean
): Order => {
  const lineItems = pipe(
    pathOr([], ['groupedLineItems']),
    map(toLineItem)
  )(order) as Array<OrderLineItem>

  const redeemable = lineItems.some((item) => item.isRedeemable)

  return {
    ...order,
    lineItems,
    shippingCostInCents: order.shippingCostInCents || 0,
    processingFeeInCents: order.processingFeeInCents || 0,
    redeemable,
    upsellDiscount,
    displayUpsells,
    saleIds: lineItems.map((obj) => obj.databaseId)
  } as Order
}

const find = async (
  orderId: string,
  apolloClient: TApolloClient
): Promise<Order> => {
  const payload = await apolloClient.query({
    query: ORDER_CONFIRMATION_QUERY,
    variables: { orderId: parseInt(orderId) }
  })

  const result = pathOr({} as GqlOrder, ['data', 'viewer', 'orders'], payload)
  const upsellDiscount = pathOr(
    0,
    ['upsellCoupon', 'percentageDiscount'],
    result
  )
  const order = pathOr({} as GqlOrder, ['edges', '0', 'node'], result)
  const displayUpsells = pathOr(false, ['edges', '0', 'displayUpsells'], result)

  return parseOrder(order, upsellDiscount, displayUpsells)
}

export default { find }
