import { useEffect, useState } from 'react'
import { captureException } from '@sentry/react'
import { isEmpty } from 'ramda'

import { isClient } from '@utils/env'
import { initializeApollo } from '@lib/apollo'
import { getCookie, setCookie } from '@utils/cookies'
import { RecentlyViewedType } from '../types/recentlyViewedTypes'
import RecentlyViewedRepository from '../repository/RecentlyViewedRepository'

export type useRecentlyViewedSalesReturnType = {
  sales: RecentlyViewedType[]
  getRecentlyViewedIds: Function
  shouldAddCurrentSale: Function
  getRecentlyViewedSales: Function
}

export type useRecentlyViewedSalesType = {
  saleId: number
  isGiveaway?: boolean
}

const useRecentlyViewedSales = ({
  saleId,
  isGiveaway = false
}: useRecentlyViewedSalesType): useRecentlyViewedSalesReturnType => {
  const [sales, setSales] = useState([] as RecentlyViewedType[])

  const getRecentlyViewedIds = (): Array<number> => {
    const cookie = getCookie('new_recently_viewed_sale_ids')
    return cookie?.split(',').map(Number) || []
  }

  const shouldAddCurrentSale = () => {
    let saleIds = getRecentlyViewedIds()

    if (!saleIds || isEmpty(saleIds)) {
      saleIds = [saleId]
      setCookie('new_recently_viewed_sale_ids', saleIds.join())
    } else if (!saleIds.find((id) => id === saleId)) {
      saleIds.unshift(saleId)
      setCookie('new_recently_viewed_sale_ids', saleIds.join())
    }
  }

  const getRecentlyViewedSales = () => {
    const apolloClient = initializeApollo()
    const saleIds = getRecentlyViewedIds()
    const firstThreeSales = saleIds.filter((id) => id !== saleId).splice(0, 3)

    RecentlyViewedRepository.find(firstThreeSales, apolloClient)
      .then((data) => {
        const sales = isGiveaway
          ? data.map((sale) => ({
              ...sale,
              slug: `/sales/${sale.slug}`
            }))
          : data

        setSales(sales)
      })
      .catch((error) => {
        captureException(error)
        setSales([])
      })
  }

  useEffect(() => {
    if (isClient()) {
      shouldAddCurrentSale()
      getRecentlyViewedSales()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    sales,
    getRecentlyViewedIds,
    shouldAddCurrentSale,
    getRecentlyViewedSales
  }
}

export default useRecentlyViewedSales
