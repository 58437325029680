import { Box, BoxProps } from 'rebass'
import { mediaSizesValues } from 'src/components/theme/breakpoints'

type ContainerProps = BoxProps & {
  withDefaultPadding?: boolean
  removeDeskPaddingBottom?: boolean
  css?: string
}

const styles = {
  container: {
    px: [4, 3],
    py: [4, 8, 10]
  },

  containerWithoutPB: {
    py: [4, 8, 0]
  }
}

const Container = ({
  sx,
  withDefaultPadding = false,
  removeDeskPaddingBottom = false,
  ...props
}: ContainerProps) => (
  <Box
    sx={{
      maxWidth: [null, ...mediaSizesValues],
      width: '100%',
      pb: 0,
      m: 'auto',
      ...(withDefaultPadding ? { ...styles.container } : {}),
      ...(removeDeskPaddingBottom ? { ...styles.containerWithoutPB } : {}),
      ...sx
    }}
    {...props}
  />
)

export default Container
