import smoothscroll from 'smoothscroll-polyfill'

import { isClient } from '@utils/env'

export type useScrollToElementReturnType = {
  scrollToElementId: Function
}

const useScrollToElement = (): useScrollToElementReturnType => {
  /* istanbul ignore else */
  if (isClient()) {
    smoothscroll.polyfill()
  }

  const scrollToElementId = (id: string) => {
    const element = document.getElementById(id) as HTMLElement
    /* istanbul ignore next */
    const headerHeight = (document.getElementById('header') as HTMLElement)
      .offsetHeight

    let targetTopPosition = 0
    let saleTabsHeight = 0
    /* istanbul ignore next */
    if (document.getElementById('saleTabs')) {
      saleTabsHeight = (document.getElementById('saleTabs') as HTMLElement)
        .offsetHeight

      targetTopPosition = element.offsetTop - headerHeight - saleTabsHeight
    } else {
      targetTopPosition = element.offsetTop - headerHeight
    }

    window.scrollTo({
      behavior: 'smooth',
      top: targetTopPosition
    })
  }

  return {
    scrollToElementId
  }
}

export default useScrollToElement
