import { createContext, Dispatch, SetStateAction, useContext } from 'react'

import { PurchaseButton } from 'src/generated/graphql'

type AddToCartContextType = {
  isSubmitting: boolean
  setIsSubmitting: Dispatch<SetStateAction<boolean>>
  purchaseButton: PurchaseButton
  isLoadingBtn: boolean
  setIsLoadingBtn: Dispatch<SetStateAction<boolean>>
}

export const AddToCartContext = createContext<AddToCartContextType | null>(null)

export const useAddToCartContext = (): AddToCartContextType => {
  const context = useContext(AddToCartContext)

  if (!context) {
    throw new Error(
      'useAddToCartContext must be used within an AddToCartProvider'
    )
  }

  return context
}
