export const formatCountdownClock = (countDown: number): string => {
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 48)) / (1000 * 60 * 60)
  )
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000)

  const formatedHours = `0${hours}`.slice(-2)
  const formatedMinutes = `0${minutes}`.slice(-2)
  const formatedSeconds = `0${seconds}`.slice(-2)

  return `${formatedHours}:${formatedMinutes}:${formatedSeconds}`
}
