import { mergeDeepRight } from 'ramda'

import Error from '@atoms/Error/Error'
import { Box, Text, Input as UIInput } from '@lib/UIComponents'
import { InputProps as UIInputProps } from '@lib/UIComponents/Input/Input'

const styles = (hasError: boolean): object => ({
  '&': {
    position: 'relative'
  },
  'label, input:placeholder-shown + label': {
    userSelection: 'none',
    cursor: 'pointer',
    transition: 'all 250ms',
    color: 'grays.600',
    position: 'absolute',
    top: '50%',
    left: 3,
    transform: 'translateY(-50%)',
    lineHeight: 1,
    touchAction: 'manipulation',
    fontSize: [1, 2],
    touchCallout: 'none',
    pointerEvents: 'none',
    zIndex: 1
  },
  input: {
    height: ['44px', '50px'],
    backgroundColor: 'background',
    borderRadius: 'default',
    border: '1px solid',
    borderColor: hasError ? 'danger' : 'grays.100',
    pt: ['19px', '21px'],
    pb: ['5px', '6px'],
    px: 3,
    lineHeight: ['18px', '21px'],
    display: 'inline-block',
    fontSize: [1, 2],
    transition: 'all 250ms',
    touchAction: 'manipulation',
    '&:focus': {
      outline: 0,
      borderColor: hasError ? 'danger' : 'blacks.500',
      boxShadow: 'none'
    },
    '&:focus-visible': {
      boxShadow: 'none'
    },
    '&::placeholder': {
      opacity: 0
    },
    '&:not(:placeholder-shown) + label, &:focus + label': {
      fontSize: 1,
      transform: ['translateY(-16px)', 'translateY(-18px)']
    }
  }
})

type InputProps = UIInputProps & {
  placeholder?: string
  error?: string
  forwardRef?: React.Ref<HTMLInputElement>
}

const Input = ({
  id,
  placeholder,
  error,
  sx = {},
  forwardRef,
  ...props
}: InputProps) => (
  <>
    <Box sx={mergeDeepRight(styles(Boolean(error)), sx as object)}>
      <UIInput
        focusBorderColor={error ? 'danger' : 'blacks.500'}
        id={id}
        placeholder={placeholder}
        ref={forwardRef}
        {...props}
      />
      <Text as="label" htmlFor={id}>
        {placeholder}
      </Text>
    </Box>

    {error && typeof error !== 'boolean' && <Error message={error} />}
  </>
)

export default Input
