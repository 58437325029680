/* istanbul ignore file */
// eslint-disable-next-line no-use-before-define
import React from 'react'
import ReactDOM from 'react-dom'
import { Notyf } from 'notyf'
import { Box, SxStyleProp } from 'rebass'
import { curry, equals, not, pathOr } from 'ramda'
import {
  Address,
  Order,
  ProcessCheckoutInput,
  Maybe,
  CartItemSource
} from 'src/generated/graphql'

import { Props } from '@concepts/Paypal/types/props'
import { Payment } from '@concepts/Paypal/types/payment'
import { Actions } from '@concepts/Paypal/types/actions'
import { Payer } from '@concepts/Paypal/types/payer'
import { SaleDetailsType, SaleTypeEnum } from '@concepts/Sales/types/sale'
import { CheckoutResponse } from '@concepts/Checkout/types/Checkout'
import { Summary } from '@concepts/Cart/types/summary'
import { Address as PaypalAddress } from '@concepts/Paypal/types/address'

import FullLoader from '@atoms/Loader/FullLoader'
import PayIn4Message from '@concepts/Paypal/views/PayIn4Message/PayIn4Message'

import PrepareCartForCheckout, {
  PrepareCartForCheckoutResult
} from '@concepts/Cart/app/PrepareCartForCheckout'
import CreateOrder from '@concepts/Paypal/app/CreateOrder'
import ProcessShippingCosts from '@concepts/Paypal/app/ProcessShippingCosts'
import ProcessCheckoutRepository from '@concepts/Payment/repository/ProcessCheckoutRepository'

import { useApollo } from '@lib/apollo'
import { useScript } from '@concepts/Paypal/hooks/useScript'
import { useFeatureFlag } from '@concepts/FeatureFlag/store/context'
import { usePublisherContext } from '@concepts/Publisher/store/context'
import { useNotyfContext } from '@atoms/Notyf/context'
import useAddSaleToCart from '@molecules/AddSaleToCart/hooks/useAddSaleToCart'
import {
  Input,
  PHONE_NUMBER_FOR_LOWER_ENVS
} from '@concepts/Paypal/hooks/usePaypal'

import errorFormatter from '@concepts/Checkout/utils/errorFormatter'
import useOrderSuccessful from '@concepts/Checkout/hooks/useOrderSuccessful'
import checkoutFilling from '@concepts/Checkout/utils/checkoutFilling'

import useMediaQuery from '@hooks/useMediaQuery'

import { getDecimalValue } from '@utils/numbers'
import { getCookie, setCookie, removeCookie } from '@utils/cookies'
import { isProduction } from '@utils/env'
import {
  PHONE_NUMBER_MISSING_ON_PAYPAL,
  SOMETHING_WENT_WRONG_WITH_PAYPAL,
  SOMETHING_WENT_WRONG
} from '@utils/defaultMessages'

import { getFingerprint } from '@middlewares/fingerprint'
import { defaultMediaSizes } from '@theme/breakpoints'
import { useCartContext } from '@concepts/Cart/store/context'

const GATEWAY = 'PAYPAL'
const CART_COOKIE = 'cart_for_single_payment_id'
const ENCRYPTED_CART_COOKIE = 'cart_for_single_payment_encrypted_id'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    paypal: any
  }
}

const styles = {
  buttonWrapper: {
    mb: [0, 5],
    zIndex: 0,
    maxWidth: ['402px', '417px'],
    '.paypal-buttons': {
      zIndex: 0
    }
  },
  payIn4Message: {
    mt: 2
  }
}

const PaypalSaleButton: React.FC<
  React.PropsWithChildren<
    Props & {
      sale: SaleDetailsType
      quantity: number
      warrantyId?: number
      bidPrice?: number
      sx?: SxStyleProp
      disabled?: boolean
      placedOnImageBackground?: boolean
    }
  >
> = ({
  sale,
  quantity,
  warrantyId,
  bidPrice = 0,
  disabled,
  placedOnImageBackground = false,
  sx = {}
}) => {
  const notyf = useNotyfContext()
  const apolloClient = useApollo()
  const FeatureFlag = useFeatureFlag()
  const { softDescriptor, currency } = usePublisherContext()
  const { setPaypalLoading, paypalLoading } = useAddSaleToCart({ sale })
  const { isReady } = useScript({
    currency: currency?.code.toUpperCase() as string,
    shippable: sale.ships
  })
  const isMobile = useMediaQuery(defaultMediaSizes.ScreenM)
  const isBundle =
    sale.type === SaleTypeEnum.Bundle || sale.type === SaleTypeEnum.BigBundle
  const { orderSuccessful } = useOrderSuccessful()
  const { coupons, credit } = useCartContext()

  const isPaypalDisabled = FeatureFlag.isEnabled(
    'paypal_pay_now_button',
    `sale:${sale.id}`
  )

  const displayPayIn4Message = FeatureFlag.isEnabled(
    'paypal_pay_in_4_message',
    'publisher'
  )

  if (isPaypalDisabled || !isReady || disabled) return null

  const prepareOrder = async (_paymentMethod: Payment, actions: Actions) => {
    return PrepareCartForCheckout.apply(
      {
        encryptedCartId: getCookie(ENCRYPTED_CART_COOKIE),
        gateway: GATEWAY,
        attributes: {
          coupon: undefined,
          applyCredits: false,
          cartForSinglePayment: true,
          forceCartClearing: true,
          item: {
            saleId: sale.id,
            quantity,
            childSaleId: warrantyId as number,
            bidPrice,
            source: CartItemSource.Pdp
          }
        }
      },
      apolloClient
    )
      .then((checkout) => {
        checkout.cart.summary = checkout.summary

        if (checkout.cart.id && checkout.encryptedCartId) {
          setCookie(CART_COOKIE, String(checkout.cart.id))
          setCookie(ENCRYPTED_CART_COOKIE, String(checkout.encryptedCartId))
        }

        return createOrder(actions, checkout)
      })
      .catch(() => {
        ;(notyf as Notyf).error({
          message: SOMETHING_WENT_WRONG
        })

        removeCookie(CART_COOKIE)
        removeCookie(ENCRYPTED_CART_COOKIE)
      })
  }

  const createOrder = (
    actions: Actions,
    checkout: PrepareCartForCheckoutResult
  ): unknown =>
    CreateOrder.execute({
      type: CreateOrder.Type.REGULAR,
      actions,
      softDescriptor,
      getAttributes: () => ({
        cart: checkout.cart,
        breakdown: ProcessShippingCosts.getBreakdownItems(
          checkout?.summary as Summary
        )
      })
    }).catch(() => {
      ;(notyf as Notyf).error({
        message: SOMETHING_WENT_WRONG_WITH_PAYPAL,
        duration: 0
      })
      setPaypalLoading(false)
    })

  const onShippingChange = ProcessShippingCosts.execute((address: Address) =>
    PrepareCartForCheckout.apply(
      {
        encryptedCartId: getCookie(ENCRYPTED_CART_COOKIE),
        gateway: GATEWAY,
        shippingAddress: address,
        billingAddress: address,
        attributes: {
          coupon: undefined,
          applyCredits: false,
          cartForSinglePayment: true,
          item: {
            saleId: sale.id,
            quantity,
            childSaleId: warrantyId as number,
            bidPrice,
            source: CartItemSource.Pdp
          }
        }
      },
      apolloClient
    ).catch(() => setPaypalLoading(false))
  )

  const onApprove = async (
    payment: Payment,
    actions: Actions
  ): Promise<void> => {
    setPaypalLoading(true)

    try {
      const paypalOrder = await actions.order.get()
      const address = paypalOrder.purchase_units[0]?.shipping?.address

      await onShippingChange(
        {
          ...payment,
          shipping_address: {
            city: address.admin_area_2,
            state: address.admin_area_1,
            country_code: address.country_code,
            postal_code: address.postal_code
          }
        },
        actions
      )
    } catch {
      setPaypalLoading(false)
      return actions.reject()
    }

    return actions.order
      .get()
      .then(getAttributes(payment))
      .catch(() => {
        setPaypalLoading(false)
      })
      .then(setResponse)
  }

  const getAddress = (payer: Payer, paypalAddress?: PaypalAddress): Address =>
    ({
      firstName: payer.name.given_name,
      lastName: payer.name.surname,
      address1: paypalAddress?.address_line_1,
      address2: paypalAddress?.address_line_2,
      countryCode: paypalAddress?.country_code,
      city: paypalAddress?.admin_area_2,
      state: paypalAddress?.admin_area_1,
      zip: paypalAddress?.postal_code,
      phoneNumber: getPhoneNumber(
        payer.phone?.phone_number?.national_number as string
      )
    }) as Address

  const getAttributes = curry(
    (payment: Payment, { payer, purchase_units }: Input) => ({
      cartId: parseInt(getCookie(CART_COOKIE) as string),
      gateway: GATEWAY,
      email: payer.email_address,
      paypalParameters: { paypalOrderId: payment.orderID },
      shippingAddress: getAddress(payer, purchase_units[0]?.shipping?.address),
      ...checkoutFilling({ coupons, credit })
    })
  )

  const completeCheckout = async (
    input: ProcessCheckoutInput
  ): Promise<CheckoutResponse> => {
    const buildError = (error: string): CheckoutResponse => ({
      success: false,
      errors: [error]
    })
    const parameters = {
      ...input,
      sessionId: getFingerprint(),
      signifydScriptLoaded: JSON.parse(
        getCookie('signifyd_script_loaded') || 'false'
      )
    }

    return ProcessCheckoutRepository(parameters, apolloClient).catch(buildError)
  }

  const setResponse = async (
    attributes: ProcessCheckoutInput
  ): Promise<void> => {
    const response = await completeCheckout(attributes)

    if (response.success) {
      removeCookie(CART_COOKIE)
      removeCookie(ENCRYPTED_CART_COOKIE)

      orderSuccessful({
        order: response.order as unknown as Order,
        gtoken: response.gtoken,
        uidocp: response.uidocp,
        gateway: GATEWAY,
        cart: response.cart
      })
    } else {
      setPaypalLoading(false)
      ;(notyf as Notyf).error({
        message: getErrors(response.errors as unknown as Error[]),
        duration: 0
      })
    }
  }

  const getPhoneNumber = (phoneNumber: Maybe<string>): Maybe<string> =>
    isProduction() ? phoneNumber : PHONE_NUMBER_FOR_LOWER_ENVS

  const getErrors = (errors: Array<Error>): string => {
    const errorPath = pathOr(
      [],
      ['0', 'graphQLErrors', '0', 'problems', '0', 'path'],
      errors
    )

    if (equals(errorPath, ['shippingAddress', 'phoneNumber'])) {
      return PHONE_NUMBER_MISSING_ON_PAYPAL
    }

    return errorFormatter(errors)
  }

  const Buttons = window.paypal.Buttons.driver('react', { React, ReactDOM })

  return (
    <>
      <FullLoader show={paypalLoading} message="Processing payment..." />

      <Box sx={{ ...styles.buttonWrapper, ...sx }}>
        {sale.ships ? (
          <Buttons
            style={{ label: 'buynow', height: 40, shape: 'pill' }}
            createOrder={prepareOrder}
            onApprove={onApprove}
            onShippingChange={onShippingChange}
          />
        ) : (
          <Buttons
            style={{ label: 'buynow', height: 40, shape: 'pill' }}
            createOrder={prepareOrder}
            onApprove={onApprove}
          />
        )}

        {displayPayIn4Message && (
          <Box sx={styles.payIn4Message}>
            <PayIn4Message
              amount={getDecimalValue(sale.priceInCents)}
              textColor={
                placedOnImageBackground && (not(isMobile) || isBundle)
                  ? 'white'
                  : 'black'
              }
            />
          </Box>
        )}
      </Box>
    </>
  )
}

export default PaypalSaleButton
