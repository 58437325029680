import { SaleDetailsType } from '@concepts/Sales/types/sale'
import { RecurringSaleGroupType } from '@concepts/Sales/dto/RecurringSaleGroupDTO'

export type ExtractedRecurringSaleGroupData = {
  annualSale: SaleDetailsType | undefined
  monthlySale: SaleDetailsType | undefined
  isChoosePlan: boolean
}

export const ExtractRecurringSaleGroupData = (
  group: RecurringSaleGroupType
): ExtractedRecurringSaleGroupData => {
  const annualSale = group?.sales?.find(
    (recSale) => recSale.recurringInterval === 'annual'
  )
  const monthlySale = group?.sales?.find(
    (recSale) => recSale.recurringInterval === 'monthly'
  )

  const isChoosePlan = Boolean(
    group?.sales?.length === 2 && annualSale && monthlySale
  )

  return {
    annualSale,
    monthlySale,
    isChoosePlan
  }
}
