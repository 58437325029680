import { mergeDeepRight } from 'ramda'

import SalePriceDropMessage from '@molecules/SalePriceDrop/Message'
import Heading from '@atoms/UIHeading/Heading'
import { SaleDetailsType, SaleTypeEnum } from '@concepts/Sales/types/sale'
import WishlistButton from '@concepts/Wishlist/views/WishlistButton'
import useScrollToElement from '@molecules/SaleTabs/hooks/useScrollToElement'
import Ratings from '@molecules/Ratings/Ratings'
import BundleInfo from '@atoms/BundleInfo/BundleInfo'
import SalePurchaseButton from '@molecules/SalePurchaseButton/SalePurchaseButton'
import useAddSaleToCart from '@molecules/AddSaleToCart/hooks/useAddSaleToCart'
import PriceSummary from '@molecules/SaleSummary/PriceSummary'
import { Box, Flex, Text } from '@lib/UIComponents'
import { SaleSummaryStyle } from './utils'

const getStyles = (
  variation: SaleSummaryStyle,
  preDropPrice: boolean,
  isRecurringSale: boolean
) => {
  const soloStyles = {
    container: {
      pb: 7,
      mb: 7,
      pl: 0,
      borderBottom: '1px solid',
      borderColor: 'grays.100',
      display: 'block',
      justifyContent: 'space-between',
      flexDirection: 'row'
    },
    titleAndWishlist: {
      position: 'relative',
      flex: 0,
      maxWidth: 'none',
      flexWrap: 'wrap',
      display: 'flex',
      flexDirection: 'column',
      borderBottom: 0,
      borderColor: 'grays.100',
      pb: 0,
      mb: 0
    },
    title: {
      pr: 8,
      fontSize: [3, 5]
    },
    bookmark: {
      top: [-1, 0],
      right: -3,
      left: 'auto'
    },
    ratings: {
      mt: [1, 1, 1, 3],
      mb: [0, 1],
      mr: 0
    },
    infoWrapper: {
      flex: !preDropPrice ? 1 : 'none',
      justifyContent: 'flex-start',
      display: 'block',
      flexDirection: 'column'
    },
    bundleInfo: {
      mt: [2, 2, 2, 0]
    },
    priceDropMessageWrapper: {
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: ['normal', 'center', 'center', 'center'],
      flexDirection: ['column', 'row', 'row', 'row', 'row', 'row'],
      mb: 0,
      lineHeight: '1.2'
    },
    priceDropMessage: {
      mt: [1, 2]
    },
    paypalButton: {},
    purchaseButton: {
      textAlign: ['left', 'left', 'left', 'right'],
      justifyContent: ['flex-start', 'flex-start', 'flex-start', 'flex-end'],
      mb: 0
    },
    purchaseButtonLoader: {
      mr: 0
    },
    deliversBy: {
      width: '100%'
    },
    freeShipping: {
      mt: 2
    },
    subscription: {
      mt: [0, 0, 0, 2],
      letterSpacing: '0.135em',
      fontSize: 'sm',
      fontWeight: 'bold'
    }
  }

  const bundleStyles = {
    container: {
      pb: 0,
      mb: 0,
      pl: [0, 0, 0, '40px'],
      borderBottom: 0,
      display: 'flex',
      flexDirection: ['column', 'column', 'column', 'row'],
      alignItems: 'flex-start'
    },
    titleAndWishlist: {
      width: '100%',
      flex: 1,
      maxWidth: ['none', 'none', 'none', '50%'],
      borderBottom: ['1px solid', '1px solid', '1px solid', 0],
      pb: [5, 5, 5, 0],
      mb: [5, 5, 5, 0],
      flexDirection: ['column', 'column', 'column', 'row', 'row']
    },
    priceDropMessageWrapper: {
      justifyContent: 'flex-start',
      flexDirection: ['column', 'row', 'row', 'row', 'row', 'row'],
      mb: 4
    },
    priceDropMessage: {
      mt: [1, 2],
      span: {
        color: 'white'
      },
      svg: {
        color: 'white'
      }
    },
    title: {
      mb: [2, 2, 2, 6],
      lineHeight: 'heading',
      fontSize: [5, '42px']
    },
    bookmark: {
      top: [0, 0, 0, 2],
      right: [-3, -1, -1, 'auto'],
      left: ['auto', 'auto', 'auto', '-46px']
    },
    ratings: {
      mr: [6, 10],
      mb: [0, 2]
    },
    infoWrapper: {
      mt: isRecurringSale ? [0, 0, 0, 2] : 0,
      justifyContent: 'flex-end',
      display: 'flex',
      alignItems: [
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-end',
        'flex-end'
      ],
      flex: 'none',
      width: ['100%', '100%', '100%', 'auto', 'auto'],
      maxWidth: isRecurringSale ? 'auto' : '420px'
    },
    purchaseButton: {
      width: '100%',
      justifyContent: [
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-end',
        'flex-end'
      ],
      mb: 7
    },
    paypalButton: {
      alignSelf: [
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-end',
        'flex-end'
      ],
      width: '100%',
      mb: 0
    },
    freeShipping: {
      mb: 4,
      mt: 0
    },
    subscription: {
      pl: [0, 0, 0, 2]
    }
  }

  switch (variation) {
    case SaleSummaryStyle.Bundle:
      return mergeDeepRight(soloStyles, bundleStyles)

    default:
      return soloStyles
  }
}

type SaleSummaryProps = {
  sale: SaleDetailsType
  showWishlistButton?: boolean
  variation?: SaleSummaryStyle
  placedOnImageBackground?: boolean
}

const shouldShowSalePriceDrop = (
  fromAffiliate: boolean,
  affiliatePriceText: string,
  retailPriceInCents: number,
  priceDescription: string,
  isGiveaway: boolean
) => {
  if (fromAffiliate && affiliatePriceText !== '') return false
  if (isGiveaway) return true

  return Boolean(retailPriceInCents) && priceDescription
}

const SaleSummary = ({
  sale,
  showWishlistButton = true,
  variation = SaleSummaryStyle.Solo,
  placedOnImageBackground = false
}: SaleSummaryProps) => {
  const { scrollToElementId } = useScrollToElement()
  const { warrantyId } = useAddSaleToCart({
    sale
  })

  const {
    id,
    priceInCents,
    retailPriceInCents,
    name,
    freeShippingMessage,
    saleReviews,
    preDropPrice,
    preDropPriceInCents,
    priceDescription,
    expiresAt,
    soldOut,
    numSold,
    numberOfCourses,
    totalHours,
    type,
    useCourseLayout,
    priceDropEndsAt,
    fromAffiliate,
    affiliatePriceText
  } = sale

  const { averageScore, totalCount } = saleReviews || {}

  const soloOrBundle =
    variation === SaleSummaryStyle.Solo || variation === SaleSummaryStyle.Bundle

  const checkedPreDropPrice =
    preDropPrice && priceInCents < preDropPriceInCents && soloOrBundle

  const applyRetailPrice = priceInCents < retailPriceInCents

  const isGiveaway = variation === SaleSummaryStyle.Giveaway
  const isRecurringSale = type === SaleTypeEnum.Recurring
  const showSalePriceDropMessage = shouldShowSalePriceDrop(
    fromAffiliate,
    affiliatePriceText,
    retailPriceInCents,
    priceDescription,
    isGiveaway
  )

  const styles = getStyles(variation, checkedPreDropPrice, isRecurringSale)

  return (
    <Box sx={styles.container} data-testid="saleSummary">
      <Box sx={styles.titleAndWishlist}>
        {isRecurringSale && <Text sx={styles.subscription}>SUBSCRIPTION</Text>}
        <Heading as="h1" sx={styles.title}>
          {name}
        </Heading>
        {showWishlistButton && (
          <WishlistButton
            saleId={id}
            source="Intelligent Module"
            sx={styles.bookmark}
          />
        )}
        <Ratings
          total={totalCount}
          average={averageScore}
          onClick={() => scrollToElementId('reviews')}
          sx={styles.ratings}
        />
        {useCourseLayout && type !== SaleTypeEnum.Freebie && (
          <BundleInfo
            enrolledQty={numSold}
            coursesQty={numberOfCourses}
            duration={totalHours}
            saleType={type}
            sx={styles.bundleInfo}
          />
        )}
      </Box>

      <Box sx={styles.infoWrapper}>
        <PriceSummary
          variation={variation}
          fromAffiliate={fromAffiliate}
          affiliatePriceText={affiliatePriceText}
          soldOut={soldOut}
          priceInCents={priceInCents}
          retailPriceInCents={retailPriceInCents}
          type={type}
          preDropPrice={preDropPrice}
          preDropPriceInCents={preDropPriceInCents}
          placedOnImageBackground={placedOnImageBackground}
        />

        <Flex sx={styles.priceDropMessageWrapper}>
          {showSalePriceDropMessage && (
            <SalePriceDropMessage
              sx={styles.priceDropMessage}
              priceDescription={priceDescription}
              expiresAt={expiresAt}
              applyRetailPrice={applyRetailPrice}
              soldOut={soldOut}
              placedOnImageBackground={placedOnImageBackground}
              variation={variation}
              priceDropEndsAt={
                checkedPreDropPrice ? priceDropEndsAt : undefined
              }
            />
          )}
        </Flex>
        {freeShippingMessage && (
          <Box sx={styles.freeShipping}>
            <Text>{freeShippingMessage}</Text>
          </Box>
        )}

        {variation === SaleSummaryStyle.Bundle && (
          <SalePurchaseButton
            sale={sale}
            warrantyId={warrantyId as number}
            sx={styles.purchaseButton}
            paypalSx={styles.paypalButton}
            loaderSx={styles.purchaseButtonLoader}
            placedOnImageBackground={placedOnImageBackground}
          />
        )}
      </Box>
    </Box>
  )
}

export default SaleSummary
