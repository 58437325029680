import { SaleTypeEnum } from '@concepts/Sales/types/sale'
import { pluralize } from '@utils/strings'

type UseBundleInfoProps = {
  enrolledQty: number
  coursesQty?: number
  duration?: number
  saleType?: string
}

const useBundleInfo = ({
  enrolledQty,
  coursesQty = 0,
  duration = 0,
  saleType = SaleTypeEnum.Bundle
}: UseBundleInfoProps) => {
  if (!enrolledQty && !coursesQty && !duration)
    return { message: null, valid: false }

  const courses = pluralize(coursesQty, 'Course', 'Courses')
  const hours = pluralize(duration, 'Hour', 'Hours')

  const getMessage = () => {
    const coursesMsg = `${coursesQty} ${courses}`
    const durationMsg = `${parseFloat(duration.toFixed(2))} ${hours}`
    const coursesAndDurationMsg = `${coursesMsg} & ${durationMsg}`

    const isBundle = saleType === SaleTypeEnum.Bundle

    if (coursesQty > 0 && duration > 0 && isBundle) return coursesAndDurationMsg
    if (coursesQty > 0 && isBundle) return coursesMsg
    if (duration > 0) return durationMsg
  }

  const message = getMessage()

  return { message, valid: true }
}

export { useBundleInfo }
