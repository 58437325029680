import { formatCountdownClock } from '@utils/clock'
import { useEffect, useState } from 'react'

const useCountdownClock = (targetDate: Date, now: Date): string => {
  const countdownDate = targetDate.getTime()

  const [countdown, setCountdown] = useState(countdownDate - now.getTime())

  useEffect(() => {
    const interval = setInterval(
      () => setCountdown(countdownDate - now.getTime()),
      1000
    )

    return () => clearInterval(interval)
  }, [countdownDate, targetDate, now])

  return formatCountdownClock(countdown)
}

export { useCountdownClock }
