import { Media } from '@molecules/SaleGallery/SaleGallery'
import { isVideo } from '../SaleGallery/Utils/DisplayThumbnails'
import VideoFrame from '@atoms/VideoFrame/VideoFrame'
import Picture from '@atoms/UIPicture/Picture'

export type Title = {
  title?: string
}

type MediaSelectorProps = {
  media: Media & Title
  saleName: string
  pictureStyles?: {
    display: string
    width: string
    borderRadius: string
  }
  index: number
  playVideo: Function
  pauseVideo: Function
  className?: string
  dataSrc?: string
}

const MediaSelector = ({
  media,
  saleName,
  pictureStyles,
  index,
  playVideo,
  pauseVideo,
  className,
  dataSrc
}: MediaSelectorProps) => {
  switch (true) {
    case isVideo(media.url):
      return (
        <VideoFrame
          url={media.url}
          playing={media.playing || false}
          onPlay={() => playVideo(index)}
          onPause={
            /* istanbul ignore next */
            () => pauseVideo(index)
          }
        />
      )
    default:
      return (
        <Picture
          sx={pictureStyles}
          src={media.url}
          className={className}
          dataSrc={dataSrc}
          alt={media.title || saleName}
        />
      )
  }
}

export default MediaSelector
