import gql from 'graphql-tag'
import { pathOr } from 'ramda'

import { TApolloClient } from 'src/types/apollo'
import RecentlyViewedDTO from '../dto/RecentlyViewedDTO'
import { RecentlyViewedType } from '../types/recentlyViewedTypes'

const SALE_DETAILS_QUERY = gql`
  query Sales($ids: [Int!]!) {
    sales(ids: $ids) {
      databaseId
      name
      slug
      priceInCents
      category {
        name
      }

      products {
        nodes {
          imageCollection {
            productShots {
              url
              altText
            }
          }
        }
      }
    }
  }
`

const find = async (
  ids: number[],
  apolloClient: TApolloClient
): Promise<RecentlyViewedType[] | []> => {
  const { data } = await apolloClient.query({
    query: SALE_DETAILS_QUERY,
    variables: { ids },
    fetchPolicy: 'cache-first'
  })

  const sales = pathOr(null, ['sales'], data)

  return sales ? RecentlyViewedDTO.parse(sales) : []
}

export default { find }
