import { addDays, addHours, formatDistanceStrict, isAfter } from 'date-fns'

import { SaleSummaryStyle } from '@molecules/SaleSummary/utils'

type Props = {
  priceDropEndsAt: string
  saleVariation: SaleSummaryStyle
  endDate: Date
  startTime?: Date
  remainingHoursCountdown: number
}

type ReturnProps = {
  countdownEndsAt: Date
  message?: string
  showCountdown?: boolean
  distanceInDays?: string
  showGenericMessage?: boolean
}

const useTimeLeft = ({
  priceDropEndsAt,
  saleVariation,
  endDate,
  startTime = new Date(),
  remainingHoursCountdown
}: Props): ReturnProps => {
  const saleEndedMessage =
    endDate < startTime &&
    `${saleVariation === 'Giveaway' ? 'Giveaway' : 'Sale'} ended`

  const countdownEndsAt = new Date(priceDropEndsAt)

  if (saleEndedMessage) return { countdownEndsAt, message: saleEndedMessage }

  if (!countdownEndsAt || countdownEndsAt < startTime) {
    return { countdownEndsAt, message: undefined }
  }

  const message = 'Expires in'

  if (isAfter(addHours(startTime, remainingHoursCountdown), countdownEndsAt)) {
    return { countdownEndsAt, message, showCountdown: true }
  }

  const showGenericMessage = isAfter(countdownEndsAt, addDays(startTime, 5))

  return {
    countdownEndsAt,
    message,
    distanceInDays: `${formatDistanceStrict(countdownEndsAt, startTime)}!`,
    showGenericMessage
  }
}

export { useTimeLeft }
