import { RefObject, useRef, useState } from 'react'
import SwiperCore from 'swiper'
import { Media } from '@molecules/SaleGallery/SaleGallery'
import { isVideo } from '@molecules/SaleGallery/Utils/DisplayThumbnails'

type returnProp = {
  prevRef: RefObject<HTMLButtonElement>
  nextRef: RefObject<HTMLButtonElement>
  medias: Media[]
  onSlideChange:
    | ((swiper: SwiperCore, speed: number, internal: any) => void)
    | undefined
  pauseVideo: Function
  playVideo: Function
}
export const useVideo = (mediaList: Media[]): returnProp => {
  const prevRef = useRef<HTMLButtonElement>(null)
  const nextRef = useRef<HTMLButtonElement>(null)
  const [medias, setMedias] = useState(mediaList)

  const onSlideChange = (e: SwiperCore) => {
    if (isVideo(medias[e.previousIndex]?.url)) {
      pauseVideo(e.previousIndex)
    }
  }

  const _setPlayingState = (index: number, state: boolean) => {
    setMedias((prevMedias) => {
      const newMedias = [...prevMedias]
      newMedias[index].playing = state

      return newMedias
    })
  }

  const pauseVideo = (index: number) => _setPlayingState(index, false)

  const playVideo = (index: number) => _setPlayingState(index, true)

  return {
    prevRef,
    nextRef,
    medias,
    onSlideChange,
    pauseVideo,
    playVideo
  }
}
