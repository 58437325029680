import { mergeDeepRight } from 'ramda'

import { EnrolledIcon, CoursesLessonsIcon } from '@atoms/Icon/Icon'
import { useBundleInfo } from './hooks/useBundleInfo'
import { Flex, Icon, Text } from '@lib/UIComponents'
import { IconType } from '@lib/UIComponents/types'

type BundleInfoProps = {
  enrolledQty?: number
  coursesQty?: number
  duration?: number
  saleType?: string
  sx?: object
}

const styles = {
  container: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  text: {
    fontSize: ['sm', 'md'],
    position: 'relative',
    top: ['-1px', '-2px', '-2px', '-2px']
  }
}

const BundleInfo = ({
  enrolledQty = 0,
  coursesQty,
  duration,
  saleType,
  sx = {}
}: BundleInfoProps) => {
  const { message, valid } = useBundleInfo({
    enrolledQty,
    coursesQty,
    duration,
    saleType
  })

  if (!valid) return <></>

  return (
    <Flex sx={mergeDeepRight(styles.container, sx)} data-testid="bundleInfo">
      {enrolledQty >= 100 && (
        <Flex sx={{ alignItems: 'center', _notFirst: { ml: 8 } }}>
          <Icon
            as={EnrolledIcon as unknown as IconType}
            sx={{ fontSize: ['lg', 'lg', '22px'], mr: 2 }}
          />
          <Text sx={styles.text}>{enrolledQty} Enrolled</Text>
        </Flex>
      )}

      {message && (
        <Flex sx={{ alignItems: 'center', _notFirst: { ml: 8 } }}>
          <Icon
            as={CoursesLessonsIcon as unknown as IconType}
            sx={{ fontSize: ['lg', 'lg', '22px'], mr: 2 }}
          />
          <Text sx={styles.text}>{message}</Text>
        </Flex>
      )}
    </Flex>
  )
}

export default BundleInfo
