import { Box, BoxProps } from 'rebass'
import Image from './Image'

type SourceTags = {
  media: string
  srcSet: string
}

type Props = {
  src: string
  alt: string
  sourceTags?: Array<SourceTags>
  className?: string
  dataSrc?: string
  css?: string
} & BoxProps

const Picture: React.FC<React.PropsWithChildren<Props>> = ({
  src,
  sourceTags,
  alt,
  className,
  dataSrc,
  ...props
}) => {
  return (
    <Box as="picture" className={className} data-src={dataSrc} {...props}>
      {sourceTags?.map(({ media, srcSet }, index: number) => (
        <source key={index} media={media} srcSet={srcSet} />
      ))}

      <Image
        sx={{ ...props.sx }}
        alt={alt}
        className={className}
        data-src={dataSrc}
        src={src}
      />
    </Box>
  )
}

export default Picture
