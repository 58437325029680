import { Image as RebassImage, ImageProps } from 'rebass'

const Image: React.FC<
  React.PropsWithChildren<
    {
      loading?: string
      css?: string
    } & ImageProps
  >
> = (props) => <RebassImage {...props} />

export default Image
